import React, { Component } from 'react'
import Header from "../layout/Header"
import Footer from "../layout/Footer"
import TopMenu from "../layout/TopMenu"
import Table from 'react-bootstrap/Table'  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getAll, deleteUser } from "../../actions"
import { connect } from "react-redux"
import Modal from 'react-bootstrap/Modal'
import { SaveLog } from '../../utils';

class UserList extends Component {

    constructor(props) {
        super(props); 
        this.state = {
            ShowModal : false,
            log_reference :new Date().getTime(),
            pre_delete : null,
            message : null,
            type_alert : null
        } 
    }

    componentDidMount() {
        SaveLog(this.state.log_reference, "List user",  true);
        this.props.getAll();  
    }

    /**
     * change page to /users/log/:id
     * @param {integer} UserID 
     */
    PageDetailUserLog(UserID) {
		this.props.history.push('/users/log/' + UserID);
	}
	
    /**
     * change page to /users/edit/:id
     * @param {integer} UserID 
     */
    PageEditUser(UserID) {
		this.props.history.push('/users/edit/' + UserID);
	}

    /**
     * toggle popup confirm delete user
     * @param {*} e 
     * @param {boolean} set 
     */
     toggleModalConfirm = (e, set, user = null) => {
        if(set === true){
           
            // const txtindex = "Are you sure to Delete ?" ;
            // this.setState({ textIndication : txtindex, titleIndication: e.target.getAttribute('data-value') });        
            // SaveLog(this.state.log_reference, "Click popup : " + e.target.getAttribute('data-value'), false)
        }
        this.setState({ ShowModal : set, pre_delete : user });
    } 

    /**
     * Confirm Delete User
     */
     onConfirmDeleteUser(){
        const objUser = {
            id : this.state.pre_delete.id,
            name : this.state.pre_delete.name,
            email : this.state.pre_delete.email,
            role_id : this.state.pre_delete.role_id,
            role : this.state.pre_delete.role.title,
        }
        SaveLog(this.state.log_reference, "Delete user " + JSON.stringify(objUser),  false);
        this.setState({ message : null, load : true });
        this.props.deleteUser(objUser.id)
        .then((reponse) => {
            console.log(reponse)
            this.setState({ ShowModal : false, pre_delete : null, load : false });
            this.props.getAll();               
        })
        .catch((e) => {                
            this.setState({ShowModal : false, pre_delete : null, load : false, message : "Can't delete user !!!", type_alert : "danger"});
            console.log(e);
        });
    }

    render() {
        let temp = this.props.store_user.datas;
        let users = [];
        if(temp && temp.data){
            users = temp.data;
        }
        const load = this.props.store_user.load;
       
        return (
            <div>
                <Header />
                <div className="container-fluid">
                    <div className="row">
                        <div className="box-tab-fixed w-100">
                            <TopMenu />
                        </div>
                    </div>

                    <div className="row row-table-content">
                        <div className="box-table w-100 " style={{ 'paddingTop' : '15px' }}>
                            <div className="main-overlay">

                            { this.state.message && <div className={`alert alert-${this.state.type_alert ? this.state.type_alert : "success"} title`} role="alert">
								{this.state.message}
							</div>}
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>E-mail</th>
                                    <th>Role</th>
                                    <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                 {  users.length > 0 && users.map(user => (
                                    <tr key={user.id}>
                                    <td>{user.id}</td>
                                    <td>{user.name}</td> 
                                    <td>{user.email}</td>
                                    <td>{user.role.title}</td>                                     
                                    <td>
                                        <button className="btn btn-info btn-circle" title="View Log" onClick={() => this.PageDetailUserLog(user.id)}> <FontAwesomeIcon icon="eye" />  </button> 
                                        { user.role_id !== 2 && (
                                            <>
                                            <button className="btn btn-secondary btn-circle" title="Edit" onClick={() => this.PageEditUser(user.id)}> <FontAwesomeIcon icon="pencil-alt" />  </button> 
                                            <button className="btn btn-danger btn-circle" title="Delete" onClick={(e) => this.toggleModalConfirm(e, true, user)}> <FontAwesomeIcon icon="trash-alt" />  </button> 
                                            </>
                                        )}
                                        
                                    </td>
                                    </tr>
                                 ))} 
                                 { users.length === 0 && (
                                    <tr>
                                    <td colSpan="5" className="text-center"> { load ? "Loading" : "No Data" }</td>                                    
                                    </tr>
                                 )}    
                                </tbody>
                                </Table>
                                {load && (
                                    <div className="overlay" ></div>
                                    )
                                }
                            </div>
                        </div>
                    </div>


                </div>
                <Footer />

                <Modal show={this.state.ShowModal} onHide={(e) => this.toggleModalConfirm(e, false)}>
                    <Modal.Header closeButton>
                    <Modal.Title>{this.state.titleIndication}</Modal.Title>
                    </Modal.Header>
                    {  this.state.pre_delete && (
                        <Modal.Body dangerouslySetInnerHTML={{__html: "Are you sure to delete \""+ this.state.pre_delete.name + "\" ?"}} ></Modal.Body>
                    )}
                    <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={(e) => this.toggleModalConfirm(e, false)}> No </button>
                    <button type="button" className="btn btn-info " onClick={(e) => this.onConfirmDeleteUser(e)}> Yes </button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
 
function mapStateToProps({ store_user }) {  
    return { store_user };
}


export default connect(mapStateToProps, { getAll, deleteUser })(UserList);