export const REIMBURSEMENTS_FETCH = "reimbursements_fetch";
export const REIMBURSEMENT_FETCH = "reimbursement_fetch";
export const REIMBURSEMENT_CREATE = "reimbursement_create";
export const REIMBURSEMENT_UPDATE = "reimbursement_update";
export const REIMBURSEMENT_FETCH_NEW = "reimbursement_fetch_new";
export const REIMBURSEMENT_FETCH_NEW_LOADDING = "reimbursement_fetch_new_loadding";
//User
export const USER_REGISTER = "USER_REGISTER";
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_UPDATE = "USER_UPDATE";
export const USER_DELETE = "USER_DELETE";
export const USER_GETALL = "USER_GETALL";
export const USER_GETBYID = "USER_GETBYID";
export const USER_LOAD = "USER_LOAD";

//HtaDocument
export const HtaDocument_FETCH = "HtaDocument_FETCH";
export const HtaDocument_CREATE = "HtaDocument_CREATE";
export const HtaDocument_UPDATE = "HtaDocument_UPDATE";
export const HtaDocuments_FETCH = "HtaDocuments_FETCH";

//ClinicalTrialDocument
export const ClinicalTrialDocument_FETCH = "ClinicalTrialDocument_FETCH";
export const ClinicalTrialDocument_CREATE = "ClinicalTrialDocument_CREATE";
export const ClinicalTrialDocument_UPDATE = "ClinicalTrialDocument_UPDATE";
export const ClinicalTrialDocuments_FETCH = "ClinicalTrialDocuments_FETCH";

//ClinicalTrial
export const ClinicalTrial_FETCH = "reimbursement_fetch";
export const ClinicalTrial_FETCH_LOADDING = "reimbursement_fetch_loadding";
export const ClinicalTrialExcel_CREATE = "ClinicalTrialExcel_create";