import React, { Component } from 'react'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { far } from '@fortawesome/free-regular-svg-icons'
import { Link,NavLink  } from "react-router-dom"
library.add(far)

export default class LeftMenu extends Component {
    render() {
        return (           
            <div className="child-box-left">
                <div className="box-menu-left">
                    <li>
                        <div className="box-round text-center"><FontAwesomeIcon icon={['far', 'file-alt']} /></div>
                        <strong className="txt-big">YOUR DOCUMENTS</strong>
                    </li>
                    <NavLink exact to="/hta_document" activeClassName='active' className="list_a"><li className="list-cs">Regulatory/HTA documents</li></NavLink>
                    <NavLink exact to="/clinical_trial_document" activeClassName='active' className="list_a"><li className="list-cs">Clinical trials documents</li></NavLink>
                    {/* <li className="list-cs">Pricing documents</li> */}
                    <li className="list-cs box-large">
                        <div className="box-round-blue"><FontAwesomeIcon icon="long-arrow-alt-left" /></div>
                        <Link to="/" className="list_a"><small>BACK TO THE SEARCH</small></Link>
                    </li>
                </div>
            </div>
            
        )
    }
}
