import React, { Component } from 'react'
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'react-bootstrap/Dropdown'
import { isAdmin,isUser } from '../../utils';
class TopMenu extends Component {

    
    render() {
        let link_clinical_trial = <NavLink exact to="/clinical_trial" activeClassName='active'><div className="button-menu"><FontAwesomeIcon icon="eye" style={{ 'fontSize': 18, 'marginRight': 3 }} /> Clinical trials tracking</div></NavLink>
        
        if(this.props.onSearch !== undefined){
            link_clinical_trial = <NavLink exact to="/clinical_trial" activeClassName='active' onClick={() => this.props.onSearch()}><div className="button-menu"><FontAwesomeIcon icon="eye" style={{ 'fontSize': 18, 'marginRight': 3 }} /> Clinical trials tracking</div></NavLink>
                
        }

    return (   
        <div className="box-list-header">
            <div className="header-tabs-menu">
                <NavLink exact to="/" activeClassName='active'><div className="button-menu active"><FontAwesomeIcon icon="eye" style={{ 'fontSize': 18, 'marginRight': 3 }} /> HTA TRACKING</div></NavLink>
                {link_clinical_trial}

                {/* <div className="button-menu"><FontAwesomeIcon icon="eye" style={{ 'fontSize': 18, 'marginRight': 3 }} /> Pricing tracking</div> */}
                
                { !isUser() && (
                <div className="box-dropdown">
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic" className="btn btn-cs border-none">
                            <div className="box-round"><FontAwesomeIcon icon="file-alt" /> </div> <span className="fa-pull-right text-black ml-1" style={{ color: '#888', marginTop: 3 }}><FontAwesomeIcon icon="angle-down" /> </span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="dropdown-custom">
                            <Link to="/hta_document" className="dropdown-item">Regulatory/HTA documents</Link>
                            <Link to="/clinical_trial_document" className="dropdown-item">Clinical trial documents</Link>
                            {/* <Link to="/" className="dropdown-item">Pricing documents</Link> */}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                )}

                <Link to="/logout"><div className="button-menu menu-float-right" ><FontAwesomeIcon icon="sign-out-alt" /> Logout</div></Link>
                { isAdmin() && (
                    <>
                    <NavLink exact to="/users" activeClassName='active'><div className="button-menu menu-float-right" ><FontAwesomeIcon icon="user" style={{ 'fontSize': 18, 'marginRight': 3 }} /> Users</div></NavLink>
                    <NavLink exact to="/users/add" activeClassName='active'><div className="button-menu menu-float-right" ><FontAwesomeIcon icon="plus" style={{ 'fontSize': 18, 'marginRight': 3 }} /> Add User</div></NavLink>
                    </>
                )}
                
                
            </div>
        </div>     
    )
    }
} 

export default TopMenu
