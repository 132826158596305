import api from './api'; 
import authHeader from './auth-header';

class UserService {
    
    getAll() {
        return api.get("/users", { headers: authHeader() });
    }

    get(id) {
        return api.get(`/users/${id}`, { headers: authHeader() });
    }

    create(data) {
        return  api.post(`/auth/register`, data, { headers: authHeader() });
    }

    update(id, data) {
        return api.put(`/users/${id}`, data, { headers: authHeader() } );
    }

    delete(id) {
        return api.delete(`/users/${id}`, { headers: authHeader() });
    }   
    
}

export default new UserService();
