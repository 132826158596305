import {
	ClinicalTrialDocument_FETCH, ClinicalTrialDocuments_FETCH, ClinicalTrialDocument_CREATE, ClinicalTrialDocument_UPDATE
} from "../actions/types";

var initialState = []
export default function(state = initialState, action) {
	switch (action.type) {
        case ClinicalTrialDocument_FETCH:
        case ClinicalTrialDocuments_FETCH:    
            return action.payload;
		case ClinicalTrialDocument_CREATE: 
            return { saved: true, msg:action.msg, type_alert:action.type_alert }
        case ClinicalTrialDocument_UPDATE: 
            return { ...state, saved: true, msg:action.msg, type_alert:action.type_alert }         
		default:
			return state;
    } 
}