import {
    USER_GETALL,
    USER_GETBYID,
    USER_LOAD,
    USER_UPDATE,
    USER_DELETE,
    USER_REGISTER
} from "./types";
import UserService from "../services/user.service";
 
export const createUser = (newUser) => async (dispatch) => {    
    try {
        const res = await UserService.create(newUser);       
		dispatch({ type: USER_REGISTER, payload: res.data });       
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const getAll = () => async (dispatch) => {
    dispatch({ type: USER_LOAD, payload: true });
    try {
        const res = await UserService.getAll();
        dispatch({ type: USER_GETALL, payload: res.data });
    } catch (err) {
        console.log(err);
    }
};

export const getById = (id) => async (dispatch) => {
    try {
        const res = await UserService.get(id);
        dispatch({ type: USER_GETBYID, payload: res.data });
        return Promise.resolve(res.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const updateUser = (id, data) => async (dispatch) => {
    try {
        const res = await UserService.update(id, data);        
        dispatch({ type: USER_UPDATE,  payload: res.data });    
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const deleteUser = (id) => async (dispatch) => {
    try {
        await UserService.delete(id);
        dispatch({ type: USER_DELETE, payload: { id }  });
    } catch (err) {
        console.log(err);
    }
};
