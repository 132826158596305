import axios from "axios";
import {
	ClinicalTrial_FETCH,
	ClinicalTrial_FETCH_LOADDING, 
	ClinicalTrialExcel_CREATE
} from "./types";
import { reset } from 'redux-form';
import {withAuth} from '../actions/header_authorization';

export const ClinicalTrialFetch = values => {	
	
	return dispatch => {
		dispatch({ type: ClinicalTrial_FETCH_LOADDING })
		
		axios			
			.post(`${process.env.REACT_APP_API_LARAVEL}/api/clinical_trial`,null, { 
				params: values,
				headers: withAuth({ 'Content-Type': 'application/json' })
			 })
			.then(res => dispatch({ type: ClinicalTrial_FETCH, payload: res.data }))
			.catch(err => {
				console.log(err)
			})
	};
	
};


export const ClinicalTrialExcel_Create = values => {
	return dispatch => {
        let formData = new FormData();
    	if(values !== undefined){
            formData.append("attach_temp", values.attach_temp[0])
        } {  }
		axios.post(`${process.env.REACT_APP_API_LARAVEL}/api/clinical_trial_excel`, formData, { 				
            headers: withAuth({ 'Content-Type': 'multipart/form-data' })
         }).then(res => {
			// console.log(res);
			if(res.data.error !== undefined){
				dispatch(reset('ClinicalTrialExcelForm')); 
				dispatch({ type: ClinicalTrialExcel_CREATE, msg:res.data.error , type_alert:"danger" });
			}else{
				dispatch(reset('ClinicalTrialExcelForm')); 
				dispatch({ type: ClinicalTrialExcel_CREATE, msg:'Data Saved Successfully', type_alert:"success" });
			}
            
		}).catch(err => {
            //console.log(err.message);
            //console.log(err.response);
			//console.log(err.response);
			let msg = err.response.data.message;
			if(msg === undefined){
				msg = "Update Excel Error!!";     
			}          
            dispatch({ type: ClinicalTrialExcel_CREATE, msg:msg, type_alert:"danger"  });
        })
	};
};
 
 