import React, { Component } from 'react'
import Header from "../layout/Header"
import Footer from "../layout/Footer"
import { connect } from "react-redux"
import TopMenu from "../layout/TopMenu"
import { HtaDocuments_Fetch,HtaDocument_Delete } from "../../actions"
import { withRouter, Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table'  
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SaveLog } from '../../utils';
class HtaDocumentList extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            log_reference :new Date().getTime()
        };
        //this.delHtaDocument = this.delHtaDocument.bind(this);
		//his.editHtaDocument = this.editHtaDocument.bind(this);
    }

    componentWillMount() {
        SaveLog(this.state.log_reference, "Management : Regulatory/HTA documents", true)       
    }

    componentWillUnmount(){   
        SaveLog(this.state.log_reference, "",  false)        
    }

    componentDidMount() {
        this.props.HtaDocuments_Fetch()
    }

    editHtaDocument(hta_document) {
        SaveLog(this.state.log_reference, "Click : Edit ID = " + hta_document.id + ", name = " + hta_document.name, false)
		this.props.history.push('/hta_document/edit/' + hta_document.id);
	}

	delHtaDocument(hta_document) {
        SaveLog(this.state.log_reference, "Click : Delete ID = " + hta_document.id + ", name = " + hta_document.name, false)
		this.props.HtaDocument_Delete(hta_document.id);
	}

    render() {
        console.log(this.props.HtaDocuments)
        return (
            <div>
                <Header />
                <div className="container-fluid">
                    <div className="row">
                        <div className="box-tab-fixed w-100">
                            <TopMenu />
                        </div>
                    </div>

                    <div className="row row-table-content">
                        <div className="box-table w-100" style={{ 'paddingTop' : '15px' }}>
                            <div>

                            <Link to="/hta_document/create"><Button variant="secondary" style={{ 'marginBottom' : '8px' }}> Add Document </Button></Link>
                               
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Date</th>
                                    <th>Document</th>
                                    <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                 { this.props.HtaDocuments.length > 0 && this.props.HtaDocuments.map(document => (
                                    <tr key={document.id}>
                                    <td>{document.id}</td>
                                    <td>{document.name}</td>
                                    <td>{document.date}</td>
                                    <td></td>
                                    <td>
                                        <button className="btn btn-info btn-circle" onClick={() => this.editHtaDocument(document)}> <FontAwesomeIcon icon="pencil-alt" />  </button> 
                                        <button className="btn btn-danger btn-circle" onClick={() => this.delHtaDocument(document)} > <FontAwesomeIcon icon="trash" /> </button>
                                    </td>
                                    </tr>
                                 ))} 
                                 { this.props.HtaDocuments.length === 0 && (
                                    <tr>
                                    <td colSpan="5" className="text-center"> No Data</td>                                    
                                    </tr>
                                 )}    
                                </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>


                </div>
                <Footer />
            </div>
        )
    }
}


function mapStateToProps({ HtaDocuments }) {
    return { HtaDocuments };
}


export default withRouter(connect(mapStateToProps, { HtaDocuments_Fetch,HtaDocument_Delete })(HtaDocumentList));