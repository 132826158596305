import {
	USER_REGISTER, USER_GETALL, USER_GETBYID, USER_LOAD, USER_UPDATE, USER_DELETE
} from "../actions/types";

const initialState = {    
    datas : null,
    data : null,
    msg : "",
    type_alert : "",
    load : false,
    payload : null,
}
export default function(state = initialState, action) {
	switch (action.type) {
		
        case USER_REGISTER:            
            return { payload : action.payload };
        case USER_UPDATE:            
            return { payload : action.payload };
        case USER_DELETE:            
            return { payload : action.payload };
        case USER_GETALL:
            return { ...state, datas : action.payload, load:false }
        case USER_GETBYID:
            return { data : action.payload, load:false }
        case USER_LOAD:
            return { ...state, load : action.payload}
        default:
			return state;
	}
}