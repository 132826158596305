import { combineReducers } from "redux"
import { reducer as reduxForm } from "redux-form"
import ReimbursementReducer from "./ReimbursementReducer"
import ClinicalTrialReducer from "./ClinicalTrialReducer"
import UserReducer from "./UserReducer"
import authentication from "./AuthReducer"
import HtaDocumentReducer from "./HtaDocumentReducer"
import ClinicalTrialDocumentReducer from "./ClinicalTrialDocumentReducer"

const rootReducer = combineReducers({     
    reimbursements: ReimbursementReducer,
    store_user: UserReducer,
    form: reduxForm,
    authentication : authentication,
    HtaDocuments : HtaDocumentReducer,    
    ClinicalTrialDocuments : ClinicalTrialDocumentReducer,
    clinical_trial_store : ClinicalTrialReducer,
});

export default rootReducer;