import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Dropdown from 'react-bootstrap/Dropdown'
import { DrugName } from '../../utils';

export default class ClinicalTrialSearch extends Component {
    constructor(props) {
        super(props);            
        const TherapeuticArr = [
            {'name' : 'ALL'},
            {'name' : 'Melanoma'},
            {'name' : 'MCC'},
            {'name' : 'CSCC'},
            {'name' : 'BCC'},
            {'name' : 'HNSCC'},
            {'name' : 'NSCLC'},
            {'name' : 'SCLC'},
            {'name' : 'CC'},
            {'name' : 'BC'},
            {'name' : 'EC'},
            {'name' : 'OC, FTC, PeC'},
            {'name' : 'CRC'},
            {'name' : 'GC +/- GEJ'},
            {'name' : 'OEC +/- GEJ'},
            {'name' : 'HCC'},
            {'name' : 'RCC'},
            {'name' : 'UC'},
            {'name' : 'PC'},
            {'name' : 'HL'},
            {'name' : 'PMBL'},
            {'name' : 'DLBCL'},
            {'name' : 'MM'},
            {'name' : 'MSI-H / dMMR'},
            {'name' : 'Glioblastoma'},
            {'name' : 'MPM'},
        ]
        const administrationArr = ['ALL', 'Mono', 'Combo'];
        const default_search = {'name' : TherapeuticArr[0]['name'], 'drug' : 'ALL'}
        this.state = { drug_name : default_search.drug ,
            therapeutic: default_search.name,           
            DrugnameArr : this.props.listName , 
            TherapeuticArr : TherapeuticArr,
            administration : administrationArr[0],
            administrationArr : administrationArr,
            };
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        this.props.onSearch(this.state.drug_name, this.state.therapeutic, this.state.administration);
    }
    
    handleClick(e){ 
        //console.log(e.target.attributes.getNamedItem('data-state').value);
        this.setState({[e.target.attributes.getNamedItem('data-state').value]: e.target.text});
        if(e.target.attributes.getNamedItem('data-flag')){
            this.setState({flag: e.target.attributes.getNamedItem('data-flag').value});
        }
    }
     

    render() {
        return (
            <div className="box-list">
                <div className="box box-md mr-3">
                <table className="w-100">
                    <tbody><tr>
                        <td><strong>Drugs</strong></td>
                        <td>
                        <Dropdown >
                        <Dropdown.Toggle variant="success" id="dropdown-basic" className="btn btn-light btn-custom dropdown-toggle btn-block"  >
                            <span className="fa-pull-left">{this.state.drug_name}</span>  
                            <span className="fa-pull-right text-black"><FontAwesomeIcon icon="angle-down"/></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu  className="dropdown-cs" >
                            {this.props.listName.map((variant, idx) => (
                            <Dropdown.Item key={idx} data-state="drug_name" onClick={this.handleClick} >{variant}</Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                        </Dropdown>
                        </td>
                    </tr>
                    </tbody></table>
                </div>
                <div className="box box-md mr-3" style={{ 'width':'19%'}}>
                    <table className="w-100">
                        <tbody><tr>
                            <td style={{ 'width': '136px'}}><strong>THERAPEUTIC AREA</strong></td>
                            <td>
                            <Dropdown >
                            <Dropdown.Toggle variant="success" id="dropdown-basic" className="btn btn-light btn-custom dropdown-toggle btn-block"  >
                                <span className="fa-pull-left">{this.state.therapeutic} </span>  
                                <span className="fa-pull-right text-black"><FontAwesomeIcon icon="angle-down"/></span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu  className="dropdown-cs" >
                                {this.state.TherapeuticArr.map((variant, idx) => (
                                <Dropdown.Item key={idx} data-state="therapeutic" data-flag={variant.flag} onClick={this.handleClick}>{variant.name}</Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                            </Dropdown>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div> 
                <div className="box box-md mr-3" style={{ 'width':'19%'}}>
                    <table className="w-100">
                        <tbody><tr>
                            <td style={{ width: '136px' }}><strong>ADMINISTRATION</strong></td>
                            <td>
                            <Dropdown >
                            <Dropdown.Toggle variant="success" id="dropdown-basic" className="btn btn-light btn-custom dropdown-toggle btn-block"  >
                                <span className="fa-pull-left">{this.state.administration} </span>  
                                <span className="fa-pull-right text-black"><FontAwesomeIcon icon="angle-down"/></span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu  className="dropdown-cs" >
                                {this.state.administrationArr.map((variant, idx) => (
                                <Dropdown.Item key={idx} data-state="administration" onClick={this.handleClick}>{variant}</Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                            </Dropdown>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="box box-sm">
                <button className="btn btn-dark btn-dark-blue btn-block" type="button" onClick={() => this.props.onSearch(this.state.drug_name, this.state.therapeutic, this.state.administration)}><FontAwesomeIcon icon="search" style={{marginRight: 5}}/> Start Search</button>
                </div>
                <div className="box box-bt-right">
                    <div className={this.props.methodology ? "btn-dark-blue btn-methodology active-bt" : "btn-dark-blue btn-methodology"}  onClick={() => this.props.onMethodology()} >METHODOLOGY</div>
                </div>
            </div>
        )
    }
}
