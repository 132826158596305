import {
	USER_LOGIN
} from "../actions/types";


export default function(state = {}, action) {
	switch (action.type) {	
        case USER_LOGIN:            
            return {loggingIn: action.loggingIn, msg: action.msg, type_alert:action.type_alert, redirect:action.redirect}
        default:
			return state;
	}
}