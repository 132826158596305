import React, { Component } from 'react';
import { connect } from "react-redux";
import { ClinicalTrialExcel_Create } from "../../actions";
import Header from "../layout/Header"
import Footer from "../layout/Footer"; 
import ClinicalTrialExcelForm from "./ClinicalTrialExcelForm"
import TopMenu from "../layout/TopMenu"
class ClinicalTrialCreate extends Component {
    
    handleSubmit(val) {
        // Do anything you want with the form value
        console.log(val);
      }        
    render () {
        const { formValues, ClinicalTrialExcel_Create } = this.props
        console.log(this.props);
        

        return (
            <div>
                
                <Header />
                <div className="container-fluid">
                    <div className="row">
                        <div className="box-tab-fixed w-100">
                            <TopMenu />
                        </div>
                    </div>

                    <div className="row row-table-content">
                        <div className="box-table w-100" style={{ 'paddingTop' : '15px' }}>
                            <div>                             
                            <ClinicalTrialExcelForm onSubmitForm={() => ClinicalTrialExcel_Create(formValues)} title="Create Clinical Trial Document" /> 
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

function mapStateToProps({ form, clinical_trial_store }) {
    console.log(clinical_trial_store)
	return { formValues: form.ClinicalTrialExcelForm ? form.ClinicalTrialExcelForm.values : null, clinical_trial_store };
}

export default connect(mapStateToProps, { ClinicalTrialExcel_Create })(ClinicalTrialCreate);