import React from 'react'
import { gen_css, smr_type, gba_added_benefit_id, recommendation, path_detail } from '../../utils';
import moment from 'moment'

export const ReimbursementTableColumn = ({type, datas, treatment_line, indexation, onClickIndication, is_all, filterAll}) => {   
    
    const colorArr = [];
    let isLast30Day = false;
    const detailPopup = [];
    const arrCheckDuplicate = [];   
    let i = 0;
    const checkTitlePastDecisions = [];
    let txtFilterAll = "";

    datas.forEach( (value) => {
        //check type "mono" or "combo" if not match to continue
        if((type === "Mono" && !value.mono) || (type === "Combo" && !value.combo)){
            return;
        }
        let check_treatment_line = value.treatment_line;
        if(value.agency_model === "Transparence" || value.agency_model === "Gba"){
            if(value.hta_decision === "Ongoing" && value.ema_data && value.ema_data.length){
                check_treatment_line = value.ema_data[0]['treatment_line'];
            }
        }
       
        //check treatment_line is match if not match to continue
        if(!check_treatment_line.includes(`,${treatment_line.value},`)){
            return;
        }

        //check color is not empty        
        if(!value.color){
            return;
        }

        //if is_all = true (for table all drug or all country)
        
        if(is_all && is_all === "name"){             
            if(!value.category.toLowerCase().includes(`${filterAll.toLowerCase()}`)){
                return;
            }
            txtFilterAll = filterAll.toUpperCase() + " ";
        }else if(is_all && is_all === "country"){
            if(value.agency_model !== filterAll.value){
                return;
            }
            txtFilterAll = filterAll.name.toUpperCase() + " ";
        }

        //check in between 30 days
        if(value.last30){
            isLast30Day = true;
        }

        

        //get data for detail popup
        const tempDetail = []; 
        const previous_decision = [];
        let txtCheckDuplicate = "";
        let guidance_date = null;
        if(value.guidance_date){
            guidance_date = moment(value.guidance_date).format('DD-MMM-YYYY');
        }
        

        if(value.agency_model === "Transparence"){

            if(value.hta_decision === "Ongoing" && value.ema_data && value.ema_data.length){
                if(value.indication){
                    tempDetail.push(<div key={i++}><strong>Indication :</strong> <span dangerouslySetInnerHTML={{ __html : value.indication }}></span></div>);
                    txtCheckDuplicate += `${value.indication.toLowerCase()}`;
                }
                if(value.ema_data[0]['administration']){
                    tempDetail.push(<div key={i++}><strong>Administration :</strong> {value.ema_data[0]['administration']}</div>);
                }
                if(value.ema_data[0]['specificity']){
                    tempDetail.push(<div key={i++}><strong>Specificity :</strong> {value.ema_data[0]['specificity']}</div>);
                }

            }else{

                if(value.asmr_title_en){
                    tempDetail.push(<div key={i++}><strong>Patient population :</strong> {value.asmr_title_en}</div>);
                    txtCheckDuplicate += `${value.asmr_title_en.toLowerCase()}`;
                }
                if(value.smr_type && value.asmr_obtenue){                
                    const smr_asmr = `SMR ${smr_type(value.smr_type)} ; ASMR ${value.asmr_obtenue}`;
                    tempDetail.push(<div key={i++}><strong>Recommendation :</strong><span> {smr_asmr}</span></div>);
                    previous_decision.push(<div key={i++}><strong>Recommendation :</strong><span> {smr_asmr}</span></div>);
                }else if(value.smr_type){              
                    tempDetail.push(<div key={i++}><strong>Recommendation :</strong><span> {smr_type(value.smr_type)}</span></div>);
                    previous_decision.push(<div key={i++}><strong>Recommendation :</strong><span> {smr_type(value.smr_type)}</span></div>);
                }
                if(value.administration){
                    tempDetail.push(<div key={i++}><strong>Administration :</strong> {value.administration}</div>);
                }
                if(value.specificity){
                    tempDetail.push(<div key={i++}><strong>Specificity :</strong> {value.specificity}</div>);
                }
            } 

            
            if(guidance_date){
                tempDetail.push(<div key={i++}><strong>Guidance date :</strong> {guidance_date}</div>);
                previous_decision.push(<div key={i++}><strong>Guidance date :</strong> {guidance_date}</div>);                
            }
            


        }else if(value.agency_model === "Gba"){
            if(value.indication){
                tempDetail.push(<div key={i++}><strong>Patient population :</strong> {value.indication}</div>);
                const tempIndication = value.indication.split("-")[0];
                txtCheckDuplicate += `${tempIndication.toLowerCase()}`;
            }
            if(value.recommendation){
                tempDetail.push(<div key={i++}><strong>Recommendation :</strong><span> {gba_added_benefit_id(value.recommendation)}</span></div>);
                previous_decision.push(<div key={i++}><strong>Recommendation :</strong><span> {gba_added_benefit_id(value.recommendation)}</span></div>);
            }

            if(value.hta_decision === "Ongoing" && value.ema_data && value.ema_data.length){                
                if(value.ema_data[0]['administration']){
                    tempDetail.push(<div key={i++}><strong>Administration :</strong> {value.ema_data[0]['administration']}</div>);
                }
                if(value.ema_data[0]['specificity']){
                    tempDetail.push(<div key={i++}><strong>Specificity :</strong> {value.ema_data[0]['specificity']}</div>);
                }
            }else{
                if(value.administration){
                    tempDetail.push(<div key={i++}><strong>Administration :</strong> {value.administration}</div>);
                }
                if(value.specificity){
                    tempDetail.push(<div key={i++}><strong>Specificity :</strong> {value.specificity}</div>);
                } 
            }
                       
            if(guidance_date){
                tempDetail.push(<div key={i++}><strong>Guidance date :</strong> {guidance_date}</div>);
                previous_decision.push(<div key={i++}><strong>Guidance date :</strong> {guidance_date}</div>);                
            }

        }else{

            if(value.indication){
                tempDetail.push(<div key={i++}><strong>Indication :</strong> <span dangerouslySetInnerHTML={{ __html : value.indication }}></span></div>);
                txtCheckDuplicate += `${value.indication.toLowerCase()}`;
            }
            if(value.administration){
                tempDetail.push(<div key={i++}><strong>Administration :</strong> {value.administration}</div>);
            }
            if(value.specificity){
                tempDetail.push(<div key={i++}><strong>Specificity :</strong> {value.specificity}</div>);
            }
            if(value.recommendation && value.agency_model !== "Colombia"){
                tempDetail.push(<div key={i++}><strong>Recommendation :</strong><span className={value.color}> {recommendation(value.agency_model, value.recommendation)}</span></div>);
                previous_decision.push(<div key={i++}><strong>Recommendation :</strong><span className={value.color}> {recommendation(value.agency_model, value.recommendation)}</span></div>);
            }
            if(guidance_date){
                tempDetail.push(<div key={i++}><strong>Guidance date :</strong> {guidance_date}</div>);
                previous_decision.push(<div key={i++}><strong>Guidance date :</strong> {guidance_date}</div>);
                txtCheckDuplicate += guidance_date;
            }

        }


        if(value.agency_model === "Spain"){
            if(value.public_text_field){            
                tempDetail.push(<div key={i++}><strong>CIPM proposal for price and reimbursement :</strong> {value.public_text_field}</div>);
            }
            if(value.public_date_field){            
                tempDetail.push(<div key={i++}><strong>CIPM date :</strong> {moment(value.public_date_field).format('DD-MMM-YYYY')}</div>);
            }
            if(value.public_text_field2){            
                tempDetail.push(<div key={i++}><strong>CIPM minutes :</strong> {value.public_text_field2}</div>);
            }
            if(value.spain_meas){   
                value.spain_meas.forEach(v => {
                    if(v.mea){
                        tempDetail.push(<div key={i++}><strong>MEA :</strong> {v.mea}</div>);
                    }
                    if(v.discount_rate){
                        tempDetail.push(<div key={i++}><strong>Discount rate :</strong> {v.discount_rate}</div>);
                    }
                    if(v.start_date_mea){
                        tempDetail.push(<div key={i++}><strong>Start date MEA :</strong> {moment(v.start_date_mea).format('DD-MMM-YYYY')}</div>);
                    }
                })
            }

        }
        
        // link url pdf
        if(value.agency_model !== "Colombia"){            
            tempDetail.push(<div key={i++}><strong>Document :</strong> <a href={path_detail(value.drug_id, value.agency_model)} target="_blank" ><i className="modal-icon-pdf"></i></a> </div>);
            previous_decision.push(<div key={i++}><strong>Document :</strong> <a href={path_detail(value.drug_id, value.agency_model)} target="_blank" ><i className="modal-icon-pdf"></i></a> </div>);
        
            //tempDetail.push(<div key={i++}><strong>Document :</strong> <span className="a-open-pdf" ><i className="modal-icon-pdf" data-path={value.agency_model+"/"+value.drug_id+".pdf"}></i></span> </div>);
            //previous_decision.push(<div key={i++}><strong>Document :</strong> <span className="a-open-pdf" ><i className="modal-icon-pdf" data-path={value.agency_model+"/"+value.drug_id+".pdf"}></i></span> </div>);
        }
        
        
        
        






        txtCheckDuplicate = txtCheckDuplicate.replace(/[^a-zA-Z0-9]/g, "");
        //console.log(txtCheckDuplicate)
        const chkDup = arrCheckDuplicate.indexOf(txtCheckDuplicate);        
        if(chkDup === -1){
            if(detailPopup.length){
                tempDetail.unshift(<div key={i++} className="line-hr"></div>);
            }
            colorArr.push(value.color);
            detailPopup.push(tempDetail);
            arrCheckDuplicate.push(txtCheckDuplicate);
        }else{
            if(!checkTitlePastDecisions.includes(chkDup)){
                detailPopup[chkDup].push(<div key={i++} ><strong>Past decisions :</strong></div>);
                checkTitlePastDecisions.push(chkDup);
            }
            detailPopup[chkDup].push(<div key={i++} className="previous_decision">{previous_decision}</div>);
        }


 
    }) 
    //
    if(colorArr.length){
        return  <td className={isLast30Day ? "pink" : ""}>
                    <div className="circle-color" 
                        data-value={`${indexation} ${txtFilterAll}${treatment_line.label} ${type}`}
                        onClick={(e) => onClickIndication(e, true)}  
                        style={{ background: gen_css(colorArr) }}
                    >   
                        <span className="tooltiptext" >{detailPopup}</span> 
                        <div className="num_decision">{colorArr.length}</div>
                    </div>
                </td>    
    }else{
        return <td></td>;
    }
    
    
}