import jwt_decode from "jwt-decode";
import axios from "axios";
import {withAuth} from '../actions/header_authorization';

export default function jwt({ dispatch, getState }) {
    return (next) => (action) => {
        //console.log('jwt');
        //console.log(typeof action);
        //console.log(action);
        if (typeof action === 'function') {
            
             
            const token = localStorage.getItem('token');
            //console.log(token);
            if (token) {
                //console.log(jwt_decode(token));
                const tokenExpiration = jwt_decode(token).exp;
                //console.log(new Date().getTime() / 1000);
                //console.log(tokenExpiration);
                //console.log(`Bearer ${token}`);
                if (tokenExpiration < (new Date().getTime() / 1000) + 1200) { //1200 = 20 minute
                    console.log('tokenExpiration');
                    axios.post(`${process.env.REACT_APP_API_LARAVEL}/api/auth/refresh`, null, {
                        //headers: { Authorization: `Bearer ${token}` }
                        headers: withAuth({ 'Content-Type': 'application/json' })
                        //headers: { Authorization : 'Bearer '+token, 'Content-Type': 'application/json' }
                    }).then(response  => {
                        localStorage.setItem('token', response.data.token);
                        return next(action);
                    })
                    .catch(err => {
                        console.log('error refreshing token', err);     
                    });
                }
            }
        }
        return next(action);
    }
}

 