import React, { Component } from "react";
import { connect } from "react-redux";
import { reimbursementFetch, reimbursementCreate } from "../../actions";
import Header from "../../components/layout/Header";
import Footer from "../../components/layout/Footer";
import ReimbursementForm from "./ReimbursementForm";


class ReimbursementCreate extends Component {

    componentDidMount(){

    }


    render() {
        const { formValues, reimbursementCreate, reimbursements } = this.props;
        return (
            
            <div>        
                <Header />        
                <div className="container col-md-5">
                    <div style={{height: '92vh', paddingTop:'35%'}}>
                    {reimbursements.saved && <div className="alert alert-success title " role="alert">
								{reimbursements.msg}
							</div>}
                    <ReimbursementForm onReimbursementSubmit={() => reimbursementCreate(formValues)} />
                    </div>
                </div>   
                <Footer />             
            </div>
        )
    }
}
function mapStateToProps({ form, reimbursements }) {
	return { formValues: form.reimbursementForm ? form.reimbursementForm.values : null, reimbursements };
}


export default connect(mapStateToProps, { reimbursementFetch, reimbursementCreate })(ReimbursementCreate);