import axios from "axios";
import {
	REIMBURSEMENT_FETCH_NEW,
	REIMBURSEMENT_FETCH_NEW_LOADDING,
	REIMBURSEMENTS_FETCH,
	REIMBURSEMENT_FETCH,
	REIMBURSEMENT_CREATE,
	REIMBURSEMENT_UPDATE
} from "./types";
import { reset } from 'redux-form';
import {withAuth} from '../actions/header_authorization';

export const reimbursementFetchNew = values => {	
	
	return dispatch => {		
		dispatch({ type: REIMBURSEMENT_FETCH_NEW, payload: []  })
		dispatch({ type: REIMBURSEMENT_FETCH_NEW_LOADDING })
		axios			
			.post(`${process.env.REACT_APP_API_LARAVEL}/api/service_prismaccess`,null, { 
				params: values,
				headers: withAuth({ 'Content-Type': 'application/json' })
			 })
			.then(res => dispatch({ type: REIMBURSEMENT_FETCH_NEW, payload: res.data }))
			.catch(err => {
				console.log(err)
			})
	};
	
};

export const reimbursementFetch = id => {	
	return dispatch => {
		axios
			.get(`${process.env.REACT_APP_API_LARAVEL}/reimbursement/read_one.php?id=${id}`)
			.then(res => dispatch({ type: REIMBURSEMENT_FETCH, payload: res.data }));
	};
};

export const reimbursementsFetch = (values) => {
	console.log(process.env);
	return dispatch => {
		axios
			.get(`${process.env.REACT_APP_API_LARAVEL}/reimbursement/read.php`)
			.then(res => dispatch({ type: REIMBURSEMENTS_FETCH, payload: res.data }));
	};
};

export const reimbursementDelete = id => {
	return dispatch => {
		axios.delete(`${process.env.REACT_APP_API_LARAVEL}/reimbursement/delete.php?id=${id}`).then(res => {
			axios
				.get(`${process.env.REACT_APP_API_LARAVEL}/reimbursement/read.php`)
				.then(res =>
					dispatch({ type: REIMBURSEMENTS_FETCH, payload: res.data })
				);
		});
	};
};

export const reimbursementCreate = values => {
	return dispatch => {
		axios.post(`${process.env.REACT_APP_API_LARAVEL}/reimbursement/create.php`, values).then(res => {
			dispatch(reset('reimbursementForm')); 
			dispatch({ type: REIMBURSEMENT_CREATE });
		});
	};
};

export const reimbursementUpdate = (id, values) => {
	return dispatch => {
		axios.put(`${process.env.REACT_APP_API_LARAVEL}/reimbursement/update.php?id=${id}`, values).then(res => {
			dispatch({ type: REIMBURSEMENT_UPDATE });
		});
	}	
};