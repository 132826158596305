import React, { Component } from 'react'
import Header from "../layout/Header"
import Footer from "../layout/Footer"
import TopMenu from "../layout/TopMenu"
import { withRouter, Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table'  
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from "axios";
import {withAuth} from '../../actions/header_authorization';

class UserLog extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            logs : []
        };
    }

    componentDidMount() {
        if (this.props.match.params.id) {
			console.log(this.props.match.params.id) 
            axios.get(`${process.env.REACT_APP_API_LARAVEL}/api/log/${this.props.match.params.id}`, { 				
                headers: withAuth({ 'Content-Type': 'application/json' })
            })
            .then(res => {
                this.setState({ logs : res.data.logs.data})
            })
            .catch(err => {
                console.log(err.response)
            })
        }

    }
    
    handleClick(e){        
        const elementTR = e.target.closest("tr");
        elementTR.getElementsByClassName('first-log')[0].classList.toggle("hidden");        
        elementTR.getElementsByClassName('sub-table')[0].classList.toggle("hidden");         
    }

    render() {
        
        return (
            <div>
                <Header />
                <div className="container-fluid">
                    <div className="row">
                        <div className="box-tab-fixed w-100">
                            <TopMenu />
                        </div>
                    </div>

                    <div className="row row-table-content">
                        <div className="box-table w-100" style={{ 'paddingTop' : '15px' }}>
                            <div>

                              
                            <Table bordered className="main-table" >
                                <thead>
                                    <tr className="text-center">
                                    <th style={{ width:"100px"}}>Date</th>
                                    <th style={{ width:"100px"}}>Time</th>
                                    <th style={{ width:"100px"}}>Use Time</th>
                                    <th style={{ width:"100px"}}>IP</th>
                                    <th colSpan="2">Detail</th>
                                    <th style={{ width:"50px"}}>URL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                 { this.state.logs.length > 0 && this.state.logs.map(log => (
                                    <tr key={log.id}>
                                    <td className="text-center">{log.date}</td>
                                    <td className="text-center">{log.start_time}</td> 
                                    <td className="text-center">{log.time_use}</td>
                                    <td className="text-center">{log.ip}</td>
                                    <td className="td-table">
                                        { log.detail.length > 0 &&  (
                                            <div className="first-log">{log.detail['0'].detail}</div>
                                        )} 

                                        <div>
                                        { log.detail.length > 1 &&  (
                                            <Table bordered className="sub-table hidden">                                               
                                                <tbody>
                                                {log.detail.map((detail, i) => (
                                                    <tr key={i}>
                                                        <td style={{ width:"180px"}} className="text-center">{detail.time}</td>
                                                        <td>{detail.detail}</td>
                                                    </tr>
                                                ))} 
                                                </tbody>
                                                
                                            </Table>
                                        )} 
                                        </div>
                                    </td> 
                                    <td className="text-center"  style={{ width:"50px"}}>
                                    { log.detail.length > 1 && ( 
                                    <button className="btn btn-info btn-circle" onClick={(e) => this.handleClick(e)} > <FontAwesomeIcon icon="eye" />  </button>
                                    )}
                                    </td>                                     
                                    <td className="text-center">
                                        <a className="btn btn-secondary btn-circle" href={log.url} target="_blank"> <FontAwesomeIcon icon="search" />  </a> 
                                    </td>
                                    </tr>
                                 ))} 
                                 { this.state.logs.length === 0 && (
                                    <tr>
                                    <td colSpan="7" className="text-center"> No Data</td>                                    
                                    </tr>
                                 )}    
                                </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>


                </div>
                <Footer />
            </div>
        )
    }
}
 

export default UserLog