import React, { Component } from 'react'
import axios from "axios";
import Header from "../layout/Header"
import Footer from "../layout/Footer"
import { connect } from "react-redux"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReimbursementSearch from './ReimbursementSearch'
import LegendLeft from './LegendLeft'
import ReimbursementTable from './ReimbursementTable'
import ReimbursementTableAllDrug from './ReimbursementTableAllDrug'
import { reimbursementFetchNew } from "../../actions"
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import TopMenu from "../layout/TopMenu"
import {withAuth} from '../../actions/header_authorization';
import { SaveLog, CountryArr, DrugName, rename_country } from '../../utils';

class Reimbursement extends Component {

    constructor(props) {
        super(props);         

        this.SearchHta = this.SearchHta.bind(this);
        this.downloadFile = this.downloadFile.bind(this);        
        this.toggleModal = this.toggleModal.bind(this);
        this.state = { DrugName : DrugName , CountryArr : CountryArr,
            name: "", country: "", flag: "", ShowModal : false, titleIndication:"", textIndication : "", date_start: null, date_end:null, text_alert:"",
            log_reference :new Date().getTime(), wait_download : false, wait_download_excel : false };
    }
    
    handleDocumentClick = e => { 
        const isClosest = e.target.closest(`[class=a-open-pdf]`);
        if (isClosest) {             
            this.download_document(e.target.getAttribute('data-path'))
        }
    };
    
    componentDidMount() {
        document.addEventListener("click", this.handleDocumentClick);
    }

    componentWillMount() {
        SaveLog(this.state.log_reference, "HTA TRACKING", true)
    } 

    componentWillUnmount(){           
        SaveLog(this.state.log_reference, "",  false);
        document.removeEventListener('click', this.handleDocumentClick); 
    }

    
    /**
     * download excel
     * 
     * @param {string} drug_name 
     * @param {string} country 
     * @returns file
     */
    downloadFile(drug_name, country) {
        SaveLog(this.state.log_reference, "Click DOWNLOAD FILE", false)
        if(drug_name == "ALL" && country == "ALL"){
            alert('Please choose a drug or a country.');
            return false;
        }

        const filename = country+'_'+drug_name;
        const typeFile = 'xlsx'
        
        country = rename_country(country);

        this.setState({wait_download_excel : true})
        axios({            
            url: `${process.env.REACT_APP_API_LARAVEL}/api/download_excel_file?drug_keyword=${drug_name}&countries=${country}`,
            method: 'GET',
            responseType: 'blob', // important
            headers:withAuth(),
          }).then((response) => {
            this.setState({wait_download_excel : false})
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            //link.setAttribute('download', this.state.country + "_" + this.state.name+'.png');
            link.setAttribute('download', filename+'.'+typeFile);
            document.body.appendChild(link);
            link.click();
          }).catch(error => {
            this.setState({wait_download_excel : false})
            alert("Download Error!! \n\nplease try again.");
            console.log(error.response)
          }); 
      
    }

    
    /**
     * main function search
     * 
     * @param  {...any} param : {drug, country, dateStart, dateEnd} 
     * 
     */
    SearchHta(...param) {
        if (param[0]) {
            if(param[0] == "ALL" && param[1] == "ALL"){
                alert('Please choose a drug or a country.');
                //this.setState({text_alert : "Please choose a drug or a country."})
                return false;
            }
            if(param[3]){
                param[3] = param[3].getFullYear() + "-" + ("0" + (param[3].getMonth() + 1)).slice(-2) 
            }
            if(param[4]){
                param[4] = param[4].getFullYear() + "-" + ("0" + (param[4].getMonth() + 1)).slice(-2) 
            }
            this.setState({
                name: param[0], country: param[1], flag: param[2],date_start: param[3],date_end: param[4]
            });
            const country = this.state.CountryArr.filter(item => item.name == param[1])[0]            
            const params = { 'name': param[0], 'country': country.value, 'date_start': param[3], 'date_end': param[4] };
  
            SaveLog(this.state.log_reference, "Search : drugs = "+param[0] + ", country = " + country.value + ", date(from) = "+param[3]+ ", date(to) = "+param[4], false)
            this.props.reimbursementFetchNew(params);
            if(Object.entries(this.props.reimbursements).length > 0 && this.props.reimbursements.constructor === Object ){
                //console.log(this.props.reimbursements);
            }
        }
    }
     
    /**
     * download result is call service screenshot
     * @param {*} e 
     * @returns file png
     */
    handleClickDownload = e => {
        SaveLog(this.state.log_reference, "Download results : drugs = "+this.state.name + ", country = " + this.state.country + ", date(from) = "+this.state.date_start+ ", date(to) = "+this.state.date_end, false)
        //let dddd =  this.state;
        this.setState({wait_download : true})
        axios({            
            url: `${process.env.REACT_APP_API_LARAVEL}/api/screenshot?drug=${this.state.name}&country=${this.state.country}`,
            method: 'GET',
            responseType: 'blob', // important
            headers:withAuth(),
          }).then((response) => {
            this.setState({wait_download : false})
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', this.state.country + "_" + this.state.name+'.png');
            document.body.appendChild(link);
            link.click();
          }).catch(error => {
              this.setState({wait_download : false})
              alert("Download Error!! \n\nplease try again.");
            console.log(error.response)
          });  
    }

    /**
     * download document in popup detail
     * @param {String} filePath 
     */
    download_document(filePath){
        
        SaveLog(this.state.log_reference, "Click Document : " + filePath, false)          
        const filename = filePath.split('/').pop(); 

        axios({            
            url: `${process.env.REACT_APP_API_LARAVEL}/api/documents/${filePath}`,
            method: 'GET',
            responseType: 'blob', // important,
            headers:withAuth(),
          }).then((response) => { 
                const file = new Blob(
                [response.data], 
                {type: 'application/pdf'});
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(fileURL);
          });  
    }

    /**
     * toggle popup detail
     * @param {*} e 
     * @param {boolean} set 
     */
    toggleModal = (e, set) => {
        //console.log(e.target.getAttribute('data-value'));
        if(set === true){
            const txtindex = e.target.querySelector('.tooltiptext').innerHTML ;
            //console.log(txtindex);
            this.setState({ textIndication : txtindex, titleIndication: e.target.getAttribute('data-value') });        
            SaveLog(this.state.log_reference, "Click popup : " + e.target.getAttribute('data-value'), false)
        }
        this.setState({ ShowModal : set });
    } 

    render() {
        const Class_flag = `flag size26 ${this.state.flag}`;
        let title_status = "HTA recommendations";
        if(['EMA', 'FDA', 'JAPAN', 'COLOMBIA'].includes(this.state.country)){
            title_status = "Approval status";
        } 
        
        return (
            <div className="page-hta">
                <Header />
                <div className="container-fluid">
                    
                    <div className="row">
                        <div className="box-tab-fixed w-100">
                            <TopMenu />
                            <ReimbursementSearch onSearch={this.SearchHta} downloadFile={this.downloadFile} {...this.state} />

                        </div>
                    </div>
                    
                    <div className="row row-table">
                        <div className="box-table w-100">
                            <div className="box-content">
                                <div className="child-box-left">
                                    <div className="box-menu-left">
                                         
                                        { this.state.country === "ALL" &&  (
                                             <li><span className="box-flag bx-flag-icon"><i className="flag size26 world" /></span> <strong className="txt-big">All countries</strong></li>
                                        )}
                                        { this.state.country !== "ALL" &&  (
                                            <li><span className="box-flag bx-flag-icon"><i className={Class_flag} /></span> <strong className="txt-big">{this.state.country}</strong></li>
                                        )}


                                        <li className="list-cs box-lg"><strong>{title_status}</strong></li>
                                            

                                        <li className="list-cs main-overlay" style={{cursor: 'pointer'}} onClick={this.handleClickDownload}> 
                                            <div className="box-round-blue"><FontAwesomeIcon icon="long-arrow-alt-down" />  </div>
                                            <small>DOWNLOAD RESULTS</small>
                                            { (this.state.wait_download ) && (
                                            <div className="overlay"></div>
                                            )}
                                        </li>
                                        <li className="list-cs">
                                            <div className="sub-box-menu">
                                             <LegendLeft country={this.state.country}/>
                                            </div>
                                        </li>
                                    </div>
                                </div>
                                
                                
                                { (this.state.country !== "ALL" && this.state.name !== "ALL") &&  (
                                <ReimbursementTable dataTable={this.props.reimbursements.data} loadding={this.props.reimbursements.loadding}  {...this.state} onClickIndication={this.toggleModal}  />
                                )}

                                { (this.state.name === "ALL" || this.state.country === "ALL") &&  (
                                <ReimbursementTableAllDrug dataTable={this.props.reimbursements.data} loadding={this.props.reimbursements.loadding}  {...this.state} onClickIndication={this.toggleModal} />
                                )} 
                            </div>
                        </div>
                    </div>
                    <a className="hide" id="hiden_link"></a>
                </div>

               

                <Modal show={this.state.ShowModal} onHide={(e) => this.toggleModal(e, false)}>
                    <Modal.Header closeButton>
                    <Modal.Title>{this.state.titleIndication}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body dangerouslySetInnerHTML={{__html: this.state.textIndication}} ></Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={(e) => this.toggleModal(e, false)}>
                        Close
                    </Button>                    
                    </Modal.Footer>
                </Modal>
                
                <Footer />
            </div>
        )
    }
}

function mapStateToProps({ reimbursements }) {  
    return { reimbursements };
}


export default connect(mapStateToProps, { reimbursementFetchNew })(Reimbursement);