import { faFan } from '@fortawesome/free-solid-svg-icons';
import React, { Component } from 'react'
import { treatmentLine } from '../../utils'
import {ReimbursementTableColumn} from './ReimbursementTableColumn'

export default class ReimbursementTableAllDrug extends Component {
    

     

    render() {

        const dataTables = this.props.dataTable.datas;     
        console.log(dataTables); 
        const loop_column = [];
        const loop_column2 = [];
        const loop_row = [];
        let temp_category = "";
        let color_row = 0;

        if(dataTables && dataTables.length){
            let i = 0; 

            if(this.props.name === "ALL"){
                this.props.DrugName.slice(1).forEach( (value, key) => {                     
                    loop_column.push(<th key={i} colSpan="2" >{value.toUpperCase()}</th>);i++;                 
                    loop_column2.push(<th key={i} style={{ width: '4.5%' }}>Mono</th>); i++; 
                    loop_column2.push(<th key={i} style={{ width: '4.5%' }}>Combo</th>); i++;     
                });
            }else if(this.props.country === "ALL"){
                this.props.CountryArr.slice(1).forEach( (value, key) => {                     
                    loop_column.push(<th key={i} colSpan="2" ><i className={"flag size17_1 " +value.flag}></i> {value.name.toUpperCase()}</th>);i++;                 
                    loop_column2.push(<th key={i} style={{ width: '4.5%' }}>Mono</th>); i++; 
                    loop_column2.push(<th key={i} style={{ width: '4.5%' }}>Combo</th>); i++;     
                });
            }


             
            dataTables.forEach( (value, key) => {
                if(!value.datas || value.datas.length === 0){
                    return;
                }

                color_row++;                
                let styleborder = false;
                if(temp_category !== value.category && temp_category !== ""){
                    styleborder = true;
                }
                temp_category = value.category;
                const datasMatchTreatmentLine = [];
                treatmentLine.forEach( treatment_line => {
                    //console.log(treatment_line.value)
                    const tempMatchTreatmentLine = [];
                    value.datas.forEach((val) => {
                        let check_treatment_line = val.treatment_line;
                        if(val.agency_model === "Transparence" || val.agency_model === "Gba"){
                            if(val.hta_decision === "Ongoing" && val.ema_data && val.ema_data.length){
                                check_treatment_line = val.ema_data[0]['treatment_line'];
                            }
                        } 
                    
                        //check treatment_line is match if not match to continue
                        if(!check_treatment_line.includes(`,${treatment_line.value},`)){
                            return;
                        }

                        //check color is not empty        
                        if(!val.color){
                            return;
                        }
                        
                        tempMatchTreatmentLine.push(val);
                    });
                    
                    
                    if(tempMatchTreatmentLine.length){
                        datasMatchTreatmentLine.push(treatment_line)
                    }                    
                });

                datasMatchTreatmentLine.forEach( (treatment_line,k) => {
                    const tempTd = [];
                    if(k === 0){
                        tempTd.push(<td rowSpan={datasMatchTreatmentLine.length}> { value.display }</td>);
                    }
                    tempTd.push(<td className="text-center-bold">{treatment_line.label}</td>);
                   
                    if(this.props.name === "ALL"){
                        this.props.DrugName.slice(1).forEach( vName => { 
                            tempTd.push(<ReimbursementTableColumn type="Mono" datas={value.datas} treatment_line={treatment_line} indexation={value.display} onClickIndication={this.props.onClickIndication} is_all="name" filterAll={vName} />); i++; 
                            tempTd.push(<ReimbursementTableColumn type="Combo" datas={value.datas} treatment_line={treatment_line} indexation={value.display} onClickIndication={this.props.onClickIndication} is_all="name" filterAll={vName} />); i++; 
                        });
                    }else if(this.props.country === "ALL"){
                        this.props.CountryArr.slice(1).forEach( vCountry => {     
                            tempTd.push(<ReimbursementTableColumn type="Mono" datas={value.datas} treatment_line={treatment_line} indexation={value.display} onClickIndication={this.props.onClickIndication} is_all="country" filterAll={vCountry} />); i++; 
                            tempTd.push(<ReimbursementTableColumn type="Combo" datas={value.datas} treatment_line={treatment_line} indexation={value.display} onClickIndication={this.props.onClickIndication} is_all="country" filterAll={vCountry} />); i++; 
                        });
                    }        
                     

                    loop_row.push(<tr key={i} className={(color_row%2 === 1 ? "tr-gray" : "")} style={{ borderTop : styleborder && k === 0 ? "solid 2px" : "" }} >{tempTd}</tr>);i++; 
                });

                 
            });
            
            
             
            
        }

        return (
            
            <div className="child-box-right">
                <div className="table-responsive main-overlay">
                    { (loop_column.length > 0) &&  (    
                    <table className="table-information">
                        <thead>
                            <tr className="header-tab_1 text-center">
                                <th rowSpan={2} style={{ width: '10%' }}>Cancer Type</th>
                                <th rowSpan={2} style={{ width: '10%' }}>Treatment Line</th>
                                 {loop_column}
                            </tr> 
                            <tr className="header-tab_2 text-center">
                                 {loop_column2}
                            </tr>
                        </thead>
                        <tbody>

                            { loop_row }
                            { (loop_row.length === 0) && 
                                (                                
                                    <tr>
                                        <td colSpan={loop_column2.length+2} className="text-center">{this.props.loadding ? 'Loading' : 'No Data'}</td>                                        
                                    </tr>
                                )
                            }
                         </tbody>
                    </table> 
                    )}                   
                    {this.props.loadding && (
                        <div className="overlay" >                           
                            {/* <i class="fa fa-refresh fa-spin"></i> */}
                        </div>
                        )
                    }
                    
                </div>
            </div>

        )
    }
}
