import React, { Component } from 'react'
import { Redirect } from "react-router-dom";
import './Login.css';
import Footer from "../layout/Footer"; 
import { connect } from "react-redux";
import { userLogin } from "../../actions";
import { reduxForm, Field } from "redux-form";
import {FormField } from "../FormField"; 
import { isLogin } from '../../utils';
import axios from "axios";

class Login extends Component {
    constructor(props) {
        super(props); 
        this.state = { 'Redirect' : false , log_reference :new Date().getTime() };
    }
    componentWillMount(){
        if(isLogin()){     
            this.setState({'Redirect' : true})            
        }
    }
    componentDidMount(){
        const postData = {
            'reference' : this.state.log_reference,
            'url' : window.location.href,
            'detail' : "Login",
            'open' : true       
        }
        axios.post(`${process.env.REACT_APP_API_LARAVEL}/api/log_guest?guest=1`, postData ,{
            headers: { 'Content-Type': 'application/json' }
        })        
    }
    componentWillUnmount(){   
        const postData = {
            'reference' : this.state.log_reference,
            'url' : window.location.href,
            'detail' : "",
            'open' : false       
        }
        axios.post(`${process.env.REACT_APP_API_LARAVEL}/api/log_guest?guest=1`, postData ,{
            headers: { 'Content-Type': 'application/json' }
        })          
       
    }
    componentWillUpdate(){ 
        if(isLogin()){     
            this.setState({'Redirect' : true})            
        }
    }
    

    render() {
        if(this.state.Redirect){
            return (<Redirect to={'/'}/>)
        }       
        const { userLogin, handleSubmit,authentication } = this.props;
        
        return (
            <div className="login">
                <div className="container-fluid">
                    <div className="header-text text-center">PD-(L)1 Regulatory, HTA and Reimbursement Landscape</div>
                    <div className="row wrapper">
                        <form className="form-signin main-overlay" onSubmit={handleSubmit(userLogin)}>    
                            <h1 className="h3 mb-3 font-weight-bold text-center">Sign In</h1>
                            {authentication.msg && <div className={`alert alert-${authentication.type_alert} title`} role="alert">
								{authentication.msg}
							</div>}
                            <Field  
                                name="email" 
                                type="email" 
                                component={FormField} 
                                label="Email address" 
                                required={true} 
                                placeholder="Enter email" 
                            />
                            <Field 
                                name="password" 
                                type="password" 
                                component={FormField} 
                                label="Password" 
                                required={true} 
                                placeholder="Password" 
                            />
                            
                            {/* <Field
                                name="remember_token"
                                id="remember_token"
                                component={FieldCheckBox}
                                type="checkbox"
                                label="Remember me" 
                            /> */}
                            <div className="form-check">
                                <input name="remember_token" id="remember_token" type="checkbox" className="form-check-input"  />
                                <label className="form-check-label" htmlFor="remember_token">Remember me</label>
                            </div>

                               
                             <button className="btn btn-md btn-primary btn-block mt-3" type="submit">Submit</button>
                            {/* <p className="mt-1 mb-3 text-right txt-14"><a className="text-secondary" >Forgot <span className="text-info">password?</span></a> </p> */}
                            
                            {authentication.loggingIn && (
                            <div className="overlay" >                           
                                {/* <i class="fa fa-refresh fa-spin"></i> */}
                            </div>
                            )}
                        </form>
                    </div>
                    
                </div>
                <Footer />
            </div>
        )
    }
}


function validate(values) {
	const errors = {};	
    if (!values.email) {
        errors.email = 'Required'
    }
    if (!values.password) {
        errors.password = 'Required'
    }
	return errors;
}
 

Login = reduxForm({ validate, form: "loginForm" })(Login);

function mapStateToProps({authentication}) {
    
	return { authentication };
}


export default connect(mapStateToProps, { userLogin })(Login);