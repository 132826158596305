import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import FormField from "../FormField";

const UploadFile = ({ input: { value: omitValue, ...inputProps }, meta: omitMeta, ...props }) => (
    <input type='file' {...inputProps} {...props} />
);

class ClinicalTrialExcelForm extends Component {


    render() {
        const { onSubmitForm, clinical_trial_store } = this.props;
        return (
           
            <div className="container">
                <div className="row justify-content-md-center">

                    <div className="col col-lg-6">
                        <h1 className="h3 mb-3 font-weight-bold text-center">UPDATE CLINICAL TRIALS TRACKING</h1>
                        <form onSubmit={this.props.handleSubmit(onSubmitForm)} >
                        {clinical_trial_store.msg && <div className={`alert alert-${clinical_trial_store.type_alert} title`} role="alert" dangerouslySetInnerHTML={{ __html : clinical_trial_store.msg}}>
                            
                        </div>}
                            <div className="form-group">
                                <label htmlFor="exampleInputPassword1">Excel file</label>
                                <Field component={UploadFile} className="form-control-file" name="attach_temp" accept='.xlsx' />
                            </div> 
                            <div className="form-group">
                                <label className="form-check-label"><a href="/nct.xlsx">Download example.</a></label>
                            </div>

                            <button type="submit" className="btn btn-primary">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function validate(values) {
    const errors = {};
    if (!values.name) {
        errors.name = 'Required'
    }
    if (!values.date) {
        errors.date = 'Required'
    }
    return errors;
}

function mapStateToProps({ clinical_trial_store }) {
    if (clinical_trial_store && clinical_trial_store.id) {
        return { initialValues: clinical_trial_store, clinical_trial_store: clinical_trial_store };
    } else {
        return { clinical_trial_store };
    }


}

ClinicalTrialExcelForm = reduxForm({ validate, form: "ClinicalTrialExcelForm" })(ClinicalTrialExcelForm);

export default connect(mapStateToProps)(ClinicalTrialExcelForm);