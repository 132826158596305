import React, { Component } from 'react'
import axios from "axios";
import Header from "../layout/Header"
import Footer from "../layout/Footer"
import { connect } from "react-redux"
import TopMenu from "../layout/TopMenu"
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { far } from '@fortawesome/free-regular-svg-icons'
import { ClinicalTrialDocuments_Fetch } from "../../actions"
import { Link  } from "react-router-dom"
import LeftMenu from "../layout/LeftMenu"
import { isAdmin, SaveLog } from '../../utils';
import Button from 'react-bootstrap/Button'
library.add(far)

class ClinicalTrialDocumentShow extends Component {

    constructor(props) {
        super(props);
        this.state = { 
             log_reference :new Date().getTime()
        };
    }

    componentWillMount() {
        SaveLog(this.state.log_reference, "Clinical trials documents", true)
        this.props.ClinicalTrialDocuments_Fetch()
    } 
    componentWillUnmount(){   
        SaveLog(this.state.log_reference, "",  false)        
    }

    DownloadFile(url, filename ){
        SaveLog(this.state.log_reference, "Click Document : " + filename, false)
        const typeFile = url.split('.').pop();  
        
        axios({            
            url: url,
            method: 'GET',
            responseType: 'blob', // important
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename+"."+typeFile);
            document.body.appendChild(link);
            link.click();
          }); 
    }

    render() {
        console.log(this.props.ClinicalTrialDocuments)
        return (
            <div>
                <Header />
                <div className="container-fluid">
                    <div className="row">
                        <div className="box-tab-fixed w-100">
                            <TopMenu />
                        </div>
                    </div>

                    <div className="row row-table-content">
                        <div className="box-table w-100">
                            <div className="box-content">
                                <LeftMenu /> 
                                <div className="child-box-right">
                                { isAdmin() && (
                                <Link to="/clinical_trial_document/list"><Button variant="secondary" style={{ 'marginBottom' : '8px', 'float' : 'right' }}> Management </Button></Link>
                                )}
                                    <div className="bg-light-gray">
                                        <div className="row">
                                        { this.props.ClinicalTrialDocuments.length > 0 && this.props.ClinicalTrialDocuments.map(document => (
                                            
                                            <div className="col-12 col-sm-6 col-md-6" key={document.id}>
                                                <div className="box-list-col w-100 align-self-center">
                                                    <div className="col-chlid"><FontAwesomeIcon style={{ 'fontSize': '5em', 'color': '#e40b7e' }} icon={['far', 'file-alt']} /></div>
                                                    <div className="col-chlid w-100">
                                                        <div className="wrap-col-content">
                                                            <div className="col-content">
                                                                <div>{document.name}</div>
                                                                <div>{document.date}</div>
                                                            </div>
                                                            <div className="col-tab-download" >
                                                                <div onClick={() => this.DownloadFile(`${process.env.REACT_APP_API_LARAVEL}${document.path}`, document.name + " " + document.date)}>
                                                                <div className="box-arrow" ><FontAwesomeIcon icon="long-arrow-alt-down" /> </div>
                                                                DOWNLOAD
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        ))} 
                                    
                                        
                                            
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <Footer />
            </div>
        )
    }
}


function mapStateToProps({ ClinicalTrialDocuments }) {
    return { ClinicalTrialDocuments };
}


export default connect(mapStateToProps, { ClinicalTrialDocuments_Fetch })(ClinicalTrialDocumentShow);