import React, { Component } from 'react'

export default class Footer extends Component {
    /*constructor(props){
        super(props);
    }*/

    render() {

        return (
            <div className="container-fluid container-footer">
                <div className="row">
                    <div className="col-12 col-md-12">
                        <div className="footer row">
                            <div className="col-12 col-md-3">
                                <span><img src="/images/logo2.png"  alt=""/> </span>
                            </div>
                            <div className="footer-info col-12 col-md-9">
                                <small>PLATFORM DEVELOPED BY PRIORITIS MARKET ACCESS CONSULTING COMPANY - ALL RIGHTS RESERVED -
                                  CONTACT - TERMS AND CONDITIONS
                                </small>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}


