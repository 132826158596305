import React, { Component } from 'react'
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer'; 
import axios from "axios";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
library.add(fas);

export default class ReimbursementShow extends Component {

    constructor(props) {
        super(props);
        this.state = { reimbursements: "" };
    }

    componentDidMount() {
        
        axios.get(`${process.env.REACT_APP_API_LARAVEL}/reimbursement/read_one.php?id=${this.props.match.params.id}`).then(res => {
            this.setState({ reimbursements: res.data });
        });
    }

    gen_css(arr) {
        let color = "";
        
        arr = arr.filter((item, index) => arr.indexOf(item) === index);        
        const arr_color = {
            'green': '#9bb713',
            'orange': '#FF9800',
            'purple': 'purple',
            'red': 'red',
            'gray': '#c5c3c3',
            'yellow': 'yellow',
            'pink': 'pink',
        };

        switch (arr.length) {
            case 2:
                color = `${arr_color[arr[0]]} 0, ${arr_color[arr[0]]} 50%, ${arr_color[arr[1]]} 50%, ${arr_color[arr[1]]} 100% `;
                break;
            case 3:
                color = `${arr_color[arr[0]]} 0, ${arr_color[arr[0]]} 33%, ${arr_color[arr[1]]} 33%, ${arr_color[arr[1]]} 66%, ${arr_color[arr[2]]} 66%, ${arr_color[arr[2]]} 100%  `;
                break;
            case 4:
                color = `${arr_color[arr[0]]} 0, ${arr_color[arr[0]]} 25%, ${arr_color[arr[1]]} 25%, ${arr_color[arr[1]]} 50%, ${arr_color[arr[2]]} 50%, ${arr_color[arr[2]]} 75%, ${arr_color[arr[3]]} 75%, ${arr_color[arr[3]]} 100% `;
                break;
            default:
                color = `${arr_color[arr[0]]} 0, ${arr_color[arr[0]]} 100% `;
                break;
        }

        return `linear-gradient(to right, ${color})`;
    }

    render() {
        let reimbursements = this.state.reimbursements;
        console.log(reimbursements.arr_name);
        const item_th = [];
        const item_th_lv2 = [];
        const item_th_lv3 = [];
        const item_tr = [];
        if (reimbursements.arr_name) {
            for (const name in reimbursements.arr_name) {
                let colspan = 0;
                for (const therapeutic_area in reimbursements.arr_name[name]) {
                    colspan = colspan + Object.keys(reimbursements.arr_name[name][therapeutic_area]).length;
                    item_th_lv2.push(<th colSpan={Object.keys(reimbursements.arr_name[name][therapeutic_area]).length} >{therapeutic_area}</th>);
                    for (const treatment_line in reimbursements.arr_name[name][therapeutic_area]) {
                        item_th_lv3.push(<th>{treatment_line}</th>);
                    }
                }
                item_th.push(<th colSpan={colspan} style={{ width: colspan * 140, }} >{name}</th>);
            }


            for (const agency in reimbursements.arr_agency) {
                const item_td = [];
                item_td.push(<td className="fix-col"><i className="flag size35 uk" /></td>);
                for (const name in reimbursements.arr_name) {
                    for (const therapeutic_area in reimbursements.arr_name[name]) {
                        for (const treatment_line in reimbursements.arr_name[name][therapeutic_area]) {
                            let color = [];
                            let title_arr = [];
                            for (const agency2 in reimbursements.arr_name[name][therapeutic_area][treatment_line]) {
                                if (agency === agency2) {
                                    if (reimbursements.arr_agency[agency][name][therapeutic_area][treatment_line]) {
                                        for (const key3 in reimbursements.arr_agency[agency][name][therapeutic_area][treatment_line]) {
                                            let value = reimbursements.arr_agency[agency][name][therapeutic_area][treatment_line][key3];
                                            if (value['I'].length > 0) {
                                                title_arr.push(value['I']);
                                            }
                                            if (value['F'].length === 0) {
                                                color.push("gray");
                                            } else {
                                                if (value['G'].length > 0) {
                                                    value['G'] = value['G'].trim();
                                                    switch (value['G']) {
                                                        case "Reimbursed":
                                                            color.push("green");
                                                            break;
                                                        case "Not reimbursed":
                                                            color.push("red");
                                                            break;
                                                        case "Reimbursed with limitation":
                                                            color.push("yellow");
                                                            break;
                                                        case "HTA awaited":
                                                            color.push("purple");
                                                            break;
                                                        case "Ongoing":
                                                            color.push("pink");
                                                            break;
                                                        case "-":
                                                            if (value['H'] === "In progress") {
                                                                color.push("orange");
                                                            }
                                                            break;

                                                        default:
                                                            break;
                                                    }
                                                }
                                            }

                                        }
                                    }
                                }
                            }
                            title_arr.filter((item, index) => title_arr.indexOf(item) === index);//<div className="line-hr"></div>
                            if (color.length === 0) {
                                item_td.push(<td></td>);
                            } else {
                                item_td.push(<td><div className="circle-color" style={{ background: this.gen_css(color) }}> <span>{title_arr.map(a => a).reduce((prev, curr) => [prev, <div className="line-hr"></div>, curr])}</span></div></td>);
                            }

                        }
                    }

                }
                item_tr.push(<tr key={agency}>{item_td}</tr>);
            }


        }
        return (
            <div className="body-gray">
                <Header />
                <div className="container-fluid">
                    <div className="row">
                        <div className="wrapper-box">

                            <div className="row">
                                                        
                                <div className="w-100 box-ui">
                                    <div className="child child-md">
                                        <p>Status</p>
                                        {/* <div className="dropdown">
                                            <button className="btn btn-light btn-custom dropdown-toggle btn-block" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <span className="fa-pull-left">HTA assessment underway</span> <span className="fa-pull-right text-black"><i className="fas fa-angle-down" /> </span>
                                            </button>
                                            <div className="dropdown-menu dropdown-cs" aria-labelledby="dropdownMenuButton">
                                                <a className="dropdown-item" ><input type="radio" /> Reimbursed</a>
                                                <a className="dropdown-item" ><input type="radio" /> HTA assessment </a>
                                                <a className="dropdown-item" ><input type="radio" /> Not assessed yet</a>
                                                <a className="dropdown-item" ><input type="radio" /> Not reimbursed</a>
                                                <a className="dropdown-item" ><input type="radio" /> No regulatory approval</a>
                                                <a className="dropdown-item" ><input type="radio" /> Reimbursed with limitations</a>
                                                <a className="dropdown-item" ><input type="radio" /> Reimbursement underway</a>
                                                <div className="text-center mt-2 mb-1">
                                                    <div className="button-menu active button-sm">OK</div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="child child-md">
                                        <p>Drugs</p>
                                        {/* <div className="dropdown">
                                            <button className="btn btn-light btn-custom dropdown-toggle btn-block" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <span className="fa-pull-left">TECENTRIQ (+ABRAXANE)</span> <span className="fa-pull-right text-black"><i className="fas fa-angle-down" /> </span>
                                            </button>
                                            <div className="dropdown-menu dropdown-cs" aria-labelledby="dropdownMenuButton">
                                                <a className="dropdown-item" >Action</a>
                                                <a className="dropdown-item" >Another action</a>
                                                <a className="dropdown-item" >Something else here</a>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="child child-lg">
                                        <p>THERAPEUTIC AREA</p>
                                        {/* <div className="dropdown">
                                            <button className="btn btn-light btn-custom dropdown-toggle btn-block" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <span className="fa-pull-left">Gastroesophageal-junction cancer</span> <span className="fa-pull-right text-black"><i className="fas fa-angle-down" /> </span>
                                            </button>
                                            <div className="dropdown-menu dropdown-cs" aria-labelledby="dropdownMenuButton">
                                                <a className="dropdown-item" >Action</a>
                                                <a className="dropdown-item" >Another action</a>
                                                <a className="dropdown-item" >Something else here</a>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="child child-sm">
                                        <p>TREATMENT LINE</p>
                                        {/* <div className="dropdown">
                                            <button className="btn btn-light btn-custom dropdown-toggle btn-block" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <span className="fa-pull-left">Adjuvant line</span> <span className="fa-pull-right text-black"><i className="fas fa-angle-down" /> </span>
                                            </button>
                                            <div className="dropdown-menu dropdown-cs" aria-labelledby="dropdownMenuButton">
                                                <a className="dropdown-item" >Action</a>
                                                <a className="dropdown-item" >Another action</a>
                                                <a className="dropdown-item" >Something else here</a>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="child child-xs">
                                        <p>MUTATION</p>
                                        {/* <div className="dropdown">
                                            <button className="btn btn-light btn-custom dropdown-toggle btn-block" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <span className="fa-pull-left">BRAF V600</span> <span className="fa-pull-right text-black"><i className="fas fa-angle-down" /> </span>
                                            </button>
                                            <div className="dropdown-menu dropdown-cs" aria-labelledby="dropdownMenuButton" style={{ minWidth: '95%' }}>
                                                <a className="dropdown-item" >Action</a>
                                                <a className="dropdown-item" >Action</a>
                                                <a className="dropdown-item" >Action</a>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="child child-xs">
                                        <p>COUNTRY</p>
                                        <div className="dropdown">
                                            <button className="btn btn-light btn-custom dropdown-toggle btn-block" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <span className="fa-pull-left">Germany</span> <span className="fa-pull-right text-black"><i className="fas fa-angle-down" /> </span>
                                            </button>
                                            {/* <div className="dropdown-menu dropdown-cs" aria-labelledby="dropdownMenuButton" style={{ minWidth: '95%' }}>
                                                <a className="dropdown-item" >Action</a>
                                                <a className="dropdown-item" >Action</a>
                                                <a className="dropdown-item" >Action</a>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="child child-sm">
                                        <p>DATE</p>
                                        <input type="text" className="form-control datetimepicker-input input-cs" id="datetimepicker5" data-toggle="datetimepicker" data-target="#datetimepicker5" placeholder="From / To" />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                            
                                <div className="col-12 col-md-12">
                                    <div className="wrapper-table">
                                        <div className="table-z">
                                            <div className="table-scroll">
                                                <table className="table-custom table-main table-fixed">
                                                    <thead>
                                                        <tr className="text-center">
                                                            <th className="fix-col" style={{ border: 'none' }} />
                                                            {item_th}
                                                        </tr>
                                                        <tr>
                                                            <th className="fix-col" style={{ border: 'none' }} />
                                                            {item_th_lv2}
                                                        </tr>
                                                        <tr>
                                                            <th className="fix-col" style={{ border: 'none' }} />
                                                            {item_th_lv3}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {item_tr}
                                                        
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="box-ui-detail mt-4">
                                            <div className="button-circle fa-pull-right" style={{ marginTop: '-5px', marginRight: 10 }}><FontAwesomeIcon icon="long-arrow-alt-down"  /></div>
                                            <ul>
                                                <li><span className="box-circle box-circle-small circle-blue" /> Reimbursed</li>
                                                <li><span className="box-circle box-circle-small circle-orange" /> REIMBURSED WITH LIMITATIONS
                                                    </li>
                                                <li><span className="box-circle box-circle-small circle-red" /> Not Reimbursed</li>
                                                <li><span className="box-circle box-circle-small circle-green" /> HTA ASSESSMENT UNDERWAY
                                                    </li>
                                                <li><span className="box-circle box-circle-small circle-gray" /> Not assessed yet</li>
                                                <li><span className="box-circle box-circle-small circle-dark-purple" /> NO REGULATORY APPROVAL
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>



                            </div>


                        </div>
                    </div>
                    <Footer />
                </div>

                
            </div>
        )
    }
}
