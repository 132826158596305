import React from "react";
import "./Error.css";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import TopMenu from "../layout/TopMenu"
const NotFound = () => {
    return (
        <div className="notfound"> 
            <Header />
            <div className="container-fluid">
                <div className="row">
                    <div className="box-tab-fixed w-100">
                        <TopMenu />
                    </div>
                </div>

                <div className="row row-table-content">
                    <div className="box-table w-100" style={{ 'paddingTop' : '15px' }}>

                        <h1>Page Not Found <small><font face="Tahoma" color="red">Error 404</font></small></h1>  
                    </div>
                </div> 
            </div>
            <Footer />

        </div>
    )
}

export default NotFound;