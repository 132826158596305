import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";

const renderField = ({ input, label, type, required, meta: { touched, error } }) => (
    <div className="form-group row">
        <label className="col-sm-2 col-form-label">{label} : </label>      
        <div className="col-sm-10">
        <input {...input} type={type} required={required} className="form-control" />  
        {touched && error && 
				(<div className="mt-2 text-danger title">{error}</div>)}         
        </div>   
        
    </div>
    )

class ReimbursementForm extends Component {

    


    render() {
        const { onReimbursementSubmit } = this.props;
        return (
            <div>
                <form onSubmit={this.props.handleSubmit(onReimbursementSubmit)}>
                    
                    <Field  name="name" type="text" component={renderField} label="Name" required={true} />
                    <Field  name="excel" type="text" component={renderField} label="Excel" required={true} />
                    <div className="form-group row">
                        <div className="col-sm-2"></div>
                        <div className="col-sm-10">
                        <button type="submit" name="action" className="btn btn-primary">Submit</button>
                        </div>
                    </div>

                     
                </form>
            </div>
        )
    }
}
function validate(values) {
	const errors = {};
	if (!values.name) {
        errors.name = 'Required'
    } 
    if (!values.excel) {
        errors.excel = 'Required'
    }
	return errors;
}


export default reduxForm({
    validate, 
    form: 'reimbursementForm' // a unique identifier for this form
  })(ReimbursementForm)