import React from 'react'

const LegendLeft = ({country}) => { 
    if(['EMA', 'FDA', 'JAPAN', 'COLOMBIA'].includes(country)){
        return (
            <ul>
                <li><span className="box-circle box-circle-small circle-green" /> Approved</li>
                <li><span className="box-circle box-circle-small circle-yellow" /> Approved with restriction</li>
                <li><span className="box-circle box-circle-small circle-red" /> Not approved</li>
                <li><span className="box-circle box-circle-small circle-black" /> Withdrawn</li>
                <li><span className="box-circle box-circle-small circle-gray" /> Ongoing</li>
            </ul>
        )
    }else if(country == "FRANCE"){
        return (
            <ul>
                <li><span className="box-circle box-circle-small" style={{ background: '#6b824d' }} /> <span className="legend-text">Recommended with reimbursement</span></li>
                <li><span className="box-circle box-circle-small" style={{ background: '#c8d68f' }} /> <span className="legend-text">Recommended with reimbursement in progress</span></li>
                <li><span className="box-circle box-circle-small" style={{ background: 'purple' }} /> <span className="legend-text">REIMBURSED BUT NOT ON T2A EXCLUSION LIST</span></li>
                <li><span className="box-circle box-circle-small circle-yellow" /> <span className="legend-text">limited RECOMMENDATION</span></li>
                <li><span className="box-circle box-circle-small circle-red" /> <span className="legend-text">Negative RECOMMENDATION</span></li>
                <li><span className="box-circle box-circle-small circle-black" /> <span className="legend-text">withdrawn</span></li>
                <li><span className="box-circle box-circle-small circle-gray" /> <span className="legend-text">Ongoing</span></li>
            </ul>
        );
    }

    return (
        <ul>
            <li><span className="box-circle box-circle-small circle-green" /> Positive RECOMMENDATION</li>
            <li><span className="box-circle box-circle-small circle-yellow" /> limited RECOMMENDATION</li>
            <li><span className="box-circle box-circle-small circle-red" /> Negative RECOMMENDATION</li>
            <li><span className="box-circle box-circle-small circle-black" /> withdrawn</li>
            <li><span className="box-circle box-circle-small circle-gray" /> Ongoing</li>
        </ul>
    );
}

export default LegendLeft