import React, {Component} from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Login from './components/Login/Login';
import Logout from './components/Login/Logout';
import ReimbursementCreate from './containers/reimbursement/ReimbursementCreate'; 
import ReimbursementList from './containers/reimbursement/ReimbursementList' 
import ReimbursementShow from './containers/reimbursement/ReimbursementShow'
import NotFound from "./components/Error/NotFound"
import Reimbursement from "./components/Reimbursement/Reimbursement"
import ViewPdf from "./components/Reimbursement/ViewPdf"
import ClinicalTrial from "./components/ClinicalTrial/ClinicalTrial"
import ClinicalTrialCreate from "./components/ClinicalTrial/ClinicalTrialCreate"
import PrivateRoute from './components/PrivateRoute'
import HtaDocumentShow from "./components/HtaDocument/HtaDocumentShow"
import HtaDocumentCreate from "./components/HtaDocument/HtaDocumentCreate"
import HtaDocumentEdit from "./components/HtaDocument/HtaDocumentEdit"
import HtaDocumentList from "./components/HtaDocument/HtaDocumentList"

import ClinicalTrialDocumentShow from "./components/ClinicalTrialDocument/ClinicalTrialDocumentShow"
import ClinicalTrialDocumentCreate from "./components/ClinicalTrialDocument/ClinicalTrialDocumentCreate"
import ClinicalTrialDocumentEdit from "./components/ClinicalTrialDocument/ClinicalTrialDocumentEdit"
import ClinicalTrialDocumentList from "./components/ClinicalTrialDocument/ClinicalTrialDocumentList"

import UserList from "./components/User/UserList"
import UserAdd from "./components/User/UserAdd"
import UserEdit from "./components/User/UserEdit"
import UserLog from "./components/User/UserLog"
class App extends Component {

  renderRouter(){
    return(
      <Switch>
        
        <Route exact path="/login" component={Login} /> 
        <Route exact path="/logout" component={Logout} />       
        <PrivateRoute exact path="/reimbursement/list" component={ReimbursementList} />
        <PrivateRoute exact path="/reimbursement/create" component={ReimbursementCreate} />
        <PrivateRoute exact path="/reimbursement/:id" component={ReimbursementShow} /> 
        <PrivateRoute exact path="/" component={Reimbursement} /> 
        <PrivateRoute exact path="/documents/:agency/:id" component={ViewPdf} />

        <PrivateRoute exact path="/hta_document" component={HtaDocumentShow} role={["Admin", "Dev", "Customer"]}/>
        <PrivateRoute exact path="/hta_document/create" component={HtaDocumentCreate} role={["Dev", "Admin"]} />
        <PrivateRoute exact path="/hta_document/edit/:id" component={HtaDocumentEdit} role={["Dev", "Admin"]} />
        <PrivateRoute exact path="/hta_document/list" component={HtaDocumentList} role={["Dev", "Admin"]} />
        
        

         

        <PrivateRoute exact path="/clinical_trial_document" component={ClinicalTrialDocumentShow} role={["Admin", "Dev", "Customer"]}/>
        <PrivateRoute exact path="/clinical_trial_document/create" component={ClinicalTrialDocumentCreate} role={["Dev", "Admin"]} />
        <PrivateRoute exact path="/clinical_trial_document/edit/:id" component={ClinicalTrialDocumentEdit} role={["Dev", "Admin"]} />
        <PrivateRoute exact path="/clinical_trial_document/list" component={ClinicalTrialDocumentList} role={["Dev", "Admin"]} />
        <PrivateRoute exact path="/clinical_trial" component={ClinicalTrial} />
        <PrivateRoute exact path="/clinical_trial_update" component={ClinicalTrialCreate} role={["Dev", "Admin"]} />
        
        
        <PrivateRoute exact path="/users/add" component={UserAdd} role={["Dev", "Admin"]} />  
        <PrivateRoute exact path="/users" component={UserList} role={["Dev", "Admin"]} />
        <PrivateRoute exact path="/users/log/:id" component={UserLog} role={["Dev", "Admin"]} />
        <PrivateRoute exact path="/users/edit/:id" component={UserEdit} role={["Dev", "Admin"]} />
        <Route path="*" component={NotFound} />
        
      </Switch>
    )
  }


  render(){
    return (
      <div>
        
        <BrowserRouter>{this.renderRouter()}</BrowserRouter>
        
      </div>
    )
  }
}

export default App;


