import React, { Component } from 'react';
import Footer from "../layout/Footer"; 
import Header from "../layout/Header"
import TopMenu from "../layout/TopMenu"
import UserForm from "./UserForm"

class Register extends Component {
    
        
    render () {        
          
        return (
            <div>
            <Header />
            <div className="container-fluid">
                <div className="row">
                    <div className="box-tab-fixed w-100">
                        <TopMenu />
                    </div>
                </div>
                <UserForm title="Register" />                
            </div>
            <Footer />
            </div>
        )
    }
}

export default Register;