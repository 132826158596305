import {
	ClinicalTrial_FETCH, ClinicalTrial_FETCH_LOADDING, ClinicalTrialExcel_CREATE
} from "../actions/types";

export default function(state = {data:[], loadding:false}, action) {
	switch (action.type) { 
        case ClinicalTrial_FETCH:
            return { ...state, loadding: false, data:action.payload}            
        case ClinicalTrial_FETCH_LOADDING:
            return { ...state, loadding: true}  
        case ClinicalTrialExcel_CREATE: 
            return { ...state, saved: true, msg:action.msg, type_alert:action.type_alert }                 
        default:
			return state;
    } 
}