import React, { Component } from 'react'
import Header from "../layout/Header"
import Footer from "../layout/Footer"
import { connect } from "react-redux"
import TopMenu from "../layout/TopMenu"
import { ClinicalTrialDocuments_Fetch,ClinicalTrialDocument_Delete } from "../../actions"
import { withRouter, Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table'  
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SaveLog } from '../../utils';
class ClinicalTrialDocumentList extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            log_reference :new Date().getTime()
        };
        //this.delClinicalTrialDocument = this.delClinicalTrialDocument.bind(this);
		//his.editClinicalTrialDocument = this.editClinicalTrialDocument.bind(this);
    }
    componentWillMount() {
        SaveLog(this.state.log_reference, "Management : Clinical trial documents", true)       
    }

    componentWillUnmount(){   
        SaveLog(this.state.log_reference, "",  false)        
    }

    
    componentDidMount() {
        this.props.ClinicalTrialDocuments_Fetch()
    }

    editClinicalTrialDocument(ClinicalTrial_document) {
        SaveLog(this.state.log_reference, "Click : Edit ID = " + ClinicalTrial_document.id + ", name = " + ClinicalTrial_document.name, false)
		this.props.history.push('/clinical_trial_document/edit/' + ClinicalTrial_document.id);
	}

	delClinicalTrialDocument(ClinicalTrial_document) {
        SaveLog(this.state.log_reference, "Click : Delete ID = " + ClinicalTrial_document.id + ", name = " + ClinicalTrial_document.name, false)
		this.props.ClinicalTrialDocument_Delete(ClinicalTrial_document.id);
	}

    render() {
        console.log(this.props.ClinicalTrialDocuments)
        return (
            <div>
                <Header />
                <div className="container-fluid">
                    <div className="row">
                        <div className="box-tab-fixed w-100">
                            <TopMenu />
                        </div>
                    </div>

                    <div className="row row-table-content">
                        <div className="box-table w-100" style={{ 'paddingTop' : '15px' }}>
                            <div>

                            <Link to="/clinical_trial_document/create"><Button variant="secondary" style={{ 'marginBottom' : '8px' }}> Add Clinical Trial Document </Button></Link>
                               
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Date</th>
                                    <th>Document</th>
                                    <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                 { this.props.ClinicalTrialDocuments.length > 0 && this.props.ClinicalTrialDocuments.map(document => (
                                    <tr key={document.id}>
                                    <td>{document.id}</td>
                                    <td>{document.name}</td>
                                    <td>{document.date}</td>
                                    <td></td>
                                    <td>
                                        <button className="btn btn-info btn-circle" onClick={() => this.editClinicalTrialDocument(document)}> <FontAwesomeIcon icon="pencil-alt" />  </button> 
                                        <button className="btn btn-danger btn-circle" onClick={() => this.delClinicalTrialDocument(document)} > <FontAwesomeIcon icon="trash" /> </button>
                                    </td>
                                    </tr>
                                 ))} 
                                 { this.props.ClinicalTrialDocuments.length === 0 && (
                                    <tr>
                                    <td colSpan="5" className="text-center"> No Data</td>                                    
                                    </tr>
                                 )}    
                                </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>


                </div>
                <Footer />
            </div>
        )
    }
}


function mapStateToProps({ ClinicalTrialDocuments }) {
    return { ClinicalTrialDocuments };
}


export default withRouter(connect(mapStateToProps, { ClinicalTrialDocuments_Fetch,ClinicalTrialDocument_Delete })(ClinicalTrialDocumentList));