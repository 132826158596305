import axios from "axios";
import { ClinicalTrialDocument_FETCH,ClinicalTrialDocuments_FETCH,ClinicalTrialDocument_CREATE,ClinicalTrialDocument_UPDATE } from "./types";
import { reset } from 'redux-form';
import {withAuth} from '../actions/header_authorization';


 export const ClinicalTrialDocuments_Fetch = () => {	
	return dispatch => {	 	
		axios			
			.get(`${process.env.REACT_APP_API_LARAVEL}/api/clinical_trial_document`, { 				
				headers: withAuth({ 'Content-Type': 'application/json' })
			 })
            .then(res => dispatch({ type: ClinicalTrialDocuments_FETCH, payload: res.data.data }) )
			.catch(err => {
				console.log(err.response)
			})
	};
	
};

export const ClinicalTrialDocument_Fetch = id => { 
	return dispatch => {
		axios.get(`${process.env.REACT_APP_API_LARAVEL}/api/clinical_trial_document/${id}`, { 				
            headers: withAuth({ 'Content-Type': 'application/json' })
         }).then(res => {
			dispatch({ type: ClinicalTrialDocument_FETCH, payload: res.data.data });
		}).catch(err => {
            console.log(err.response);            
        })
	};
};
export const ClinicalTrialDocument_Delete = id => { 
	return dispatch => {
		axios.delete(`${process.env.REACT_APP_API_LARAVEL}/api/clinical_trial_document/${id}`,{ 				
            headers: withAuth({ 'Content-Type': 'application/json' })
         }).then(res => {
			axios.get(`${process.env.REACT_APP_API_LARAVEL}/api/clinical_trial_document`, { 				
                headers: withAuth({ 'Content-Type': 'application/json' })
             }).then(res =>
					dispatch({ type: ClinicalTrialDocuments_FETCH, payload: res.data.data })
				);
		});
	};
};

export const ClinicalTrialDocument_Create = values => {
	return dispatch => {
        
        let formData = new FormData();
        formData.append("name",values.name)
        formData.append("date",values.date)
        if(values.attach_temp !== undefined){
            formData.append("attach_temp", values.attach_temp[0])
        } {  }
		axios.post(`${process.env.REACT_APP_API_LARAVEL}/api/clinical_trial_document`, formData, { 				
            headers: withAuth({ 'Content-Type': 'multipart/form-data' })
         }).then(res => {
            dispatch(reset('ClinicalTrialDocumentFormmm')); 
			dispatch({ type: ClinicalTrialDocument_CREATE, msg:'Data Saved Successfully', type_alert:"success" });
		}).catch(err => {
            console.log(err);
            console.log(err.response);
            //console.log(err.response);
            let msg = "Create Document Error!!";
            //if(err.response.data.status !== undefined){
            //    msg = err.response.data.status
            //}
            dispatch({ type: ClinicalTrialDocument_CREATE, msg:msg, type_alert:"danger"  });
        })
	};
};
export const ClinicalTrialDocument_Update = (id, values) => {
     
	return dispatch => { 
        console.log(values);
        let formData = new FormData();
        formData.append("name",values.name)
        formData.append("date",values.date)
        if(values.attach_temp !== undefined){
            formData.append("attach_temp", values.attach_temp[0])
        }
        //method "put" not working on upload file
        //axios.put(`${process.env.REACT_APP_API_LARAVEL}/api/clinical_trial_document/${id}`, formData, { 	
		axios.post(`${process.env.REACT_APP_API_LARAVEL}/api/clinical_trial_document/${id}`, formData, { 				
            headers: withAuth({ 'Content-Type': 'multipart/form-data' })
         }).then(res => {
            dispatch(reset('ClinicalTrialDocumentFormmm')); 
			dispatch({ type: ClinicalTrialDocument_UPDATE, msg:'Data Saved Successfully',type_alert:"success" });
		}).catch(err => {
            //.log(err.response);
            let msg = "Create Document Error!!";
            if(err.response.data.status !== undefined){
                msg = err.response.data.status
            }
            dispatch({ type: ClinicalTrialDocument_UPDATE, msg:msg, type_alert:"danger"  });
        })
	};
};



