import React from "react"; 

export function FormField ({ input, label, type, required,placeholder, meta: { touched, error } }) {
    return (
    <div className="form-group">
        <label><strong>{label}</strong></label> 
        <input {...input} type={type} required={required} placeholder={placeholder} className="form-control" />  
        {touched && error && 
				(<div className="mt-2 text-danger title">{error}</div>)}   
    </div>
    );
}

export function FormFieldSelect({ option, input, label, required, meta: { touched, error } }) {
    

    return (
        <div className="form-group">
            <label><strong>{label}</strong></label> 
            <select {...input} required={required}  className="form-control" > 
            <option></option>
            {
            option.map(function(item, i){
                
                return <option key={i}  value={item['value']}>{item['label']}</option>
            })
            }
            </select> 
            {touched && error && 
                    (<div className="mt-2 text-danger title">{error}</div>)}   
        </div>
        );
}

export function FormFieldSelectd ({ input, label, type, required,placeholder, meta: { touched, error } }) {
    return (
    <div className="form-group">
        <label><strong>{label}</strong></label> 
        <select {...input} required={required}  className="form-control" > 

        </select> 
        {touched && error && 
				(<div className="mt-2 text-danger title">{error}</div>)}   
    </div>
    );
}

export function FieldCheckBox ({ input, id,  label, type, required, meta: { touched, error } }){
   
    return (        
        <div className="form-check">
            <input {...input} id={id} type={type} className="form-check-input" />  
            <label className="form-check-label" htmlFor={id}>{label}</label>
        </div>
    );
}

export function Fieldhidden ({ input, value }){
    return (
    <div>
        
        <input  {...input} type="hidden" value={value} />
 
        
    </div>
    );
}