import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Methodology extends Component {
    constructor(props) {
        super(props);
        this.state = {            
            activeTab: 'tab1',
            tabs: [
                'tab1',
                'tab2',
                'tab3',
                'tab4',
            ]
        };
    }

    toggle_tabs(this_tab){        
        this.setState({'activeTab':this_tab})
    }

    render() {

        return (
            <div className="wrapper-content w-100">

                <ul className="nav nav-pills mb-2 nav-pills-custom" id="pills-tab" role="tablist">
                    <li className="nav-item">
                        <a className={"nav-link" + (this.state.activeTab === this.state.tabs[0] ? " active" : "")} onClick={() => this.toggle_tabs(this.state.tabs[0])} >1. Identification of clinical trials</a>
                    </li>
                    <li className="nav-item">
                        <a className={"nav-link" + (this.state.activeTab === this.state.tabs[1] ? " active" : "")} onClick={() => this.toggle_tabs(this.state.tabs[1])}>2. Classification of clinical trials</a>
                    </li>
                    <li className="nav-item">
                        <a className={"nav-link" + (this.state.activeTab === this.state.tabs[2] ? " active" : "")} onClick={() => this.toggle_tabs(this.state.tabs[2])}>3. Expected filing date</a>
                    </li>
                    <li className="nav-item">
                        <a className={"nav-link" + (this.state.activeTab === this.state.tabs[3] ? " active" : "")} onClick={() => this.toggle_tabs(this.state.tabs[3])}>4. Matching and probability</a>
                    </li>
                </ul>
                <div className="tab-content" >
                    <div className={"tab-pane fade" + (this.state.activeTab === this.state.tabs[0] ? " show active" : "")} role="tabpanel" aria-labelledby="pills-home-tab">
                        <div className="px-1">
                            <div className="box-content-info">
                                <div className="bx-child-content bx-first-chlid"><div className="box-text-header"><span>Retrieve <br />relevant data</span></div>
                                </div>
                                <div className="bx-child-content bx-last-child">
                                    <div className="bx-details">
                                        <ul>
                                            <li className="title-nolist">
                                                <div className="bx-title-header">Sources</div>
                                            </li>
                                            <li>Press releases, news websites</li>
                                            <li>Financial, R&amp;D, and pipeline reports from pharma</li>
                                            <li>Publications from congresses (ASCO, ESMO)</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="box-arrow-down"><img src="./images/arrow-down.png" alt="" /></div>
                            <div className="box-content-info">
                                <div className="bx-child-content bx-first-chlid">
                                <div className="box-text-header"><span>Supplement design from clinicaltrials.gov</span></div></div>
                                <div className="bx-child-content bx-last-child">
                                    <div className="bx-details">
                                        <ul>
                                            <li className="title-nolist"><div className="bx-title-header">Data</div></li>
                                            <li>Study phase</li>
                                            <li>Trial name</li>
                                            <li>Objective from official title</li>
                                        </ul>
                                        <ul>
                                            <li>Primary endpoints</li>
                                            <li>Comparators</li>
                                            <li>Key dates (e.g. primary completion date)</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="box-arrow-down"><img src="./images/arrow-down.png" alt="" /></div>
                            <div className="box-content-info">
                                <div className="bx-child-content bx-first-chlid"><div className="box-text-header"><span>Add expected filing date</span></div></div>
                                <div className="bx-child-content bx-last-child">
                                    <div className="bx-details">
                                        <ul style={{ marginTop: 25 }}>
                                            <li>Added whenever available by pharma (FDA/EMA expected filing dates)</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"tab-pane fade" + (this.state.activeTab === this.state.tabs[1] ? " show active" : "")} role="tabpanel" aria-labelledby="pills-profile-tab">
                        <div className="px-1">
                            <div className="box-content-info">
                                <div className="bx-child-content bx-first-chlid"><div className="box-text-header"><span>Filter</span></div>
                                </div>
                                <div className="bx-child-content bx-last-child">
                                    <div className="bx-details">
                                        <ul>
                                            <li className="title-nolist"><div className="bx-title-header">Clinical trials excluded</div></li>                                            
                                            <li>Phase 1 and Phase 1/2 trials</li>
                                            <li>Phase 2 "proof of concept"</li>
                                            <li>Trials not dedicated to a specific tumor type (e.g. 'solid tumors')</li>
                                            <li>Trials where the drug of interest is not PD-1/PD-L1</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="box-arrow-down"><img src="./images/arrow-down.png" alt="" /></div>
                            <div className="box-content-info">
                                <div className="bx-child-content bx-first-chlid">
                                <div className="box-text-header"><span>Classify clinical trials by importance</span></div></div>
                                <div className="bx-child-content bx-last-child">
                                    <div className="bx-details">
                                        <ul style={{ marginTop: 37 }}>
                                            <li>Conducted by the Prioritis consultant team using criteria described on the
                                            following slides
                                                        </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="box-arrow-down"><img src="./images/arrow-down.png" alt="" /></div>
                            <div className="box-content-info">
                                <div className="bx-child-content bx-first-chlid"><div className="box-text-header"><span>Order clinical trials</span></div></div>
                                <div className="bx-child-content bx-last-child">
                                    <div className="bx-details">
                                        <ul>
                                            <li>Trials ordered by Expected filing date</li>
                                            <li>If the Expected filing date not available, the primary completion date of the clinical trial is used (Expected filing date = primary completion date + "4 to 8 months")
                </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"tab-pane fade" + (this.state.activeTab === this.state.tabs[2] ? " show active" : "")} role="tabpanel" aria-labelledby="pills-contact-tab">
                        <div className="box-wrap">
                            <div className="header-title"><span>Items considered when classifying Expected filing date</span>
                            </div>
                            <div className="box-content-info">
                                <div className="bx-child-content bx-first-chlid"><div className="box-text-header"><span>Expected filing date</span></div></div>
                                <div className="bx-child-content bx-last-child">
                                    <div className="bx-details">
                                        <ul style={{ marginTop: 25 }}>
                                            <li>An expected filing date will positively influence the importance of a trial
                                            as it demonstrates an intention to file
                </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="box-content-info">
                                <div className="bx-child-content bx-first-chlid"><div className="box-text-header"><span>Primary completion date</span></div></div>
                                <div className="bx-child-content bx-last-child">
                                    <div className="bx-details">
                                        <ul style={{ marginTop: 25 }}>
                                            <li>Usually posted and fixed in advance, sometimes delayed</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="box-content-info">
                                <div className="bx-child-content bx-first-chlid"><div className="box-text-header"><span>Retrospective analysis</span></div></div>
                                <div className="bx-child-content bx-last-child">
                                    <div className="bx-details">
                                        <ul>
                                            <li>Filing date calculated from an analysis retrieving data from Prismaccess and
                                            Clinicaltrials.gov
                </li>
                                            <li>2015-2019 analysis on PD-1/PD-L1 (58 items) : Marketing authorization
                                            EMA/FDA is 210-350 days following primary completion date (less than 4
                                            months)
                </li>
                                            <li>Our analysis : Expected filing date = primary completion date + "4 to 8 months"
                </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"tab-pane fade" + (this.state.activeTab === this.state.tabs[3] ? " show active" : "")} role="tabpanel" aria-labelledby="pills-contact-tab">
                        <div className="box-wrap mb-2">
                            <div className="header-title"><span>Items considered when classifying clinical trials</span></div>
                            <div className="box-content-info">
                                <div className="bx-child-content bx-first-chlid"><div className="box-text-header"><span>Study design</span></div></div>
                                <div className="bx-child-content bx-last-child">
                                    <div className="bx-details">
                                        <ul>
                                            <li className="title-nolist">Assessment of the study design of the trial is assessed based on:</li>
                                            <li>Phase</li>
                                            <li>Comparators</li>
                                            <li>Endpoint</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="box-content-info">
                                <div className="bx-child-content bx-first-chlid bg-pink"><div className="box-text-header"><span>Press release / Congress publication</span>
                                </div></div>
                                <div className="bx-child-content bx-last-child">
                                    <div className="bx-details"> 
                                        <ul>
                                            <li className="title-nolist mb-3">
                                            The sentiment from a press release or congress publication is
                                        considered and
                                        can improve or lessen the probability rating of a trial to obtain market approval:
                                            </li>   
                                            <li className="mb-3">Positive (e.g. "met primary endpoint") : likely <strong className="text-green">very
                    high</strong> probability
                </li>
                                            <li className="mb-3">No data : no data from press release or congress publication
                                            results, but
                  expected filing date confirmed of pharma pipeline : likely <strong className="text-green">high</strong> or <strong className="text-orange">medium </strong> 
                  probability
                </li>
                                            <li>Negative (e.g. "primary endpoint not met") : likely <strong className="text-red">"null" </strong> 
                  probability <FontAwesomeIcon icon="arrow-right"  style={{ 'fontSize': 14 }} /> excluded
                </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="box-content-info">
                                <div className="bx-child-content bx-first-chlid bg-pink"><div className="box-text-header"><span>Matching NICE ongoing assessments</span></div>
                                </div>
                                <div className="bx-child-content bx-last-child">
                                    <div className="bx-details"> 
                                        <ul>
                                            <li className="title-nolist mb-3">
                                            <strong>NICE ongoing assessments</strong> that have not yet been
                                            published are matched to pharma pipeline
                                            and clinical trials by comparing population or endpoints, wherever possible, and
                                            given a matching score:

                                            </li>
                                            <li className="mb-3">an <strong>expected publication date</strong> <FontAwesomeIcon icon="arrow-right"  style={{ 'fontSize': 14 }} /> likely <strong className="text-green">very
                    high</strong> probability
                </li>
                                            <li className="mb-3"><strong>"in progress"</strong> or <strong>"proposed" </strong>
                  status with no expected publication date : likely <strong className="text-green">high</strong> probability
                </li>
                                            <li className="mb-3"><strong>"suspended"</strong> when the company has advised that
                  it will review the regulatory timelines at EMA for this indication : likely
                  <strong className="text-orange"> medium</strong> probability
                </li>
                                            <li className="mb-3"><strong>"suspended"</strong> when the company has advised that
                  they will not be pursuing a licensing application
                  from the EMA for this indication : likely
                  <strong className="text-yellow"> low </strong> probability (Does not impact FDA)
                </li>
                                            <li><strong>"suspended"</strong> when withdrawn EMA filing <FontAwesomeIcon icon="arrow-right"  style={{ 'fontSize': 14 }} /> likely <strong className="text-red">"null" </strong>
                  probability (Does not impact FDA)
                </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="box-content-info">
                                <div className="bx-child-content bx-first-chlid bg-pink"><div className="box-text-header"><span>Matching and probability <br /><br /> Other situations</span></div></div>
                                <div className="bx-child-content bx-last-child">
                                    <div className="bx-details">
                                        <ul>
                                            <li className="mb-3"><strong>When Primary completion date is reviewed and delayed
                    more than 10 months : </strong>  likely <strong className="text-yellow">low</strong> probability or pharma pipeline.
                </li>
                                            <li className="mb-3"><strong>When Primary completion date is older than one
                    year : </strong> likely <strong className="text-yellow">low</strong> probability when no primary
                  endpoint results available from press release
                </li>
                                            <li className="mb-3">Phase 2 study not taken into account when the disease is not
                                            precise (solid tumor)
                </li>



                                            <li>Studies are not considered if it is a Phase 2 study of activity… (proof of concept), and when the endpoints are focused on safety, pharmacokinetics or interaction.</li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}




export default Methodology;