import React, { Component } from 'react'
import axios from "axios";
import {withAuth} from '../../actions/header_authorization';
export default class ViewPdf extends Component {
     
    componentDidMount(){
        this.download_document()
    }

    download_document(){
        
        const filePath = this.props.match.params.agency + "/" + this.props.match.params.id;
        

        axios({            
            url: `${process.env.REACT_APP_API_LARAVEL}/api/documents/${filePath}`,
            method: 'GET',
            responseType: 'blob', // important,
            headers:withAuth(),
          }).then((response) => { 
                const file = new Blob(
                [response.data], 
                {type: 'application/pdf'});
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(fileURL, "_self" );
          });  
    }

    render() {
        return (
            <div>
                
            </div>
        )
    }
}
 
 