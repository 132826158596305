import React, { Component } from 'react'

export default class Header extends Component {
    render() {
        return (
            
                <header>
                    <div className="nav-header row flex-nowrap justify-content-between align-items-center row-cs fixed-top bg-white">
                        <div>
                            
                            <span className="header-logo header-logo-sm"><img src="/images/logo1.png" alt="" className="img-responsive" /></span>
                            <strong className="text-dark-blue txt-20">PD-(L)1 Regulatory, HTA and Reimbursement Landscape</strong>
                             
                        </div>
                        <div className=" d-flex justify-content-end align-items-right text-right">
                            <span className="logo-right"><img src="/images/logo4.jpg" alt="" /></span>
                        </div>
                    </div>
                </header>

            
        )
    }
}
