import React, { Component } from 'react';
import { connect } from "react-redux";
import { ClinicalTrialDocument_Fetch, ClinicalTrialDocument_Update } from "../../actions";
import Header from "../layout/Header"
import Footer from "../layout/Footer"; 
import ClinicalTrialDocumentForm from "./ClinicalTrialDocumentForm"
import TopMenu from "../layout/TopMenu"
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import { SaveLog } from '../../utils';
class ClinicalTrialDocumentEdit extends Component {
    
    constructor(props) {
        super(props);
        this.state = { 
            log_reference :new Date().getTime()
        }; 
    } 

    componentWillUnmount(){   
        SaveLog(this.state.log_reference, "",  false)        
    }

    componentDidMount() {
        
		if (this.props.match.params.id) {
            SaveLog(this.state.log_reference, "Edit Clinical Trial Document ID : " + this.props.match.params.id, true)  
			this.props.ClinicalTrialDocument_Fetch(this.props.match.params.id);
		}
    }    
    componentWillUpdate(){
        //console.log('componentDidUpdate')
       // this.props.ClinicalTrialDocument_Fetch(this.props.match.params.id);
    }  
    componentWillReceiveProps(nextProps) {
        console.log('componentWillReceiveProps')
        console.log(nextProps)
    /* if (nextProps.foo.isDeleted) {
        this.props.router.push('/your-route');
    } */
    }
    
    render () {
        const { formValues, ClinicalTrialDocuments, ClinicalTrialDocument_Update } = this.props
        console.log(ClinicalTrialDocuments)

        return (
            <div>
                <Header />
                
                <div className="container-fluid">
                    <div className="row">
                        <div className="box-tab-fixed w-100">
                            <TopMenu />
                        </div>
                    </div>

                    <div className="row row-table-content">
                        <div className="box-table w-100" style={{ 'paddingTop' : '15px' }}>
                            <div>
                            <Link to="/clinical_trial_document/list"><Button variant="secondary" style={{ 'marginBottom' : '8px' }}> List Document </Button></Link>
                              
                            { ClinicalTrialDocuments && ClinicalTrialDocuments.id && (                
                            <ClinicalTrialDocumentForm onSubmitForm={() => ClinicalTrialDocument_Update(ClinicalTrialDocuments.id, formValues)} title="Edit Clinical Trial Document" />           
                            )}
                            </div>
                        </div>
                    </div>


                </div>

                <Footer />
            </div>
        )
    }
}

function mapStateToProps({ form, ClinicalTrialDocuments }) {
	return { formValues: form.ClinicalTrialDocumentFormmm ? form.ClinicalTrialDocumentFormmm.values : null, ClinicalTrialDocuments };
}

export default connect(mapStateToProps, { ClinicalTrialDocument_Update , ClinicalTrialDocument_Fetch })(ClinicalTrialDocumentEdit);