import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import reduxThunk from "redux-thunk";
import reducers from "./reducers";
import jwt from "./middleware/jwt"
//import './style/reset.css';
import "bootstrap/dist/css/bootstrap.min.css"
import './style/style2.css'
import './style/custom.css'

const store = createStore(reducers, {}, applyMiddleware(jwt, reduxThunk));

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById("root")
);
