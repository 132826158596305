import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form"; 
import {FormField} from "../FormField";

const UploadFile = ({ input: {value: omitValue, ...inputProps }, meta: omitMeta, ...props }) => (
    <input type='file' {...inputProps} {...props} />
  );

class ClinicalTrialDocumentForm extends Component {
	

	render() {
		const { onSubmitForm, ClinicalTrialDocuments } = this.props;
		return (			 
            <form className="form-signin" onSubmit={this.props.handleSubmit(onSubmitForm)} >  
                <h1 className="h3 mb-3 font-weight-bold text-center">{this.props.title}</h1>
                {ClinicalTrialDocuments.msg && <div className={`alert alert-${ClinicalTrialDocuments.type_alert} title`} role="alert">
                    {ClinicalTrialDocuments.msg}
                </div>} 
                <Field  name="name" type="text" component={FormField} label="Document name" required={true} placeholder="Enter document name" />
                <Field  name="date" type="text" component={FormField} label="Date" required={true} placeholder="YYYY-MM-DD" />
                <Field component={UploadFile} name="attach_temp" accept='.pdf' />
                <br></br>
                <button type="submit" className="btn btn-lg btn-primary btn-block" > Submit </button>
            </form>
			 
		);
	}
}

function validate(values) {
	const errors = {};
	if (!values.name) {
        errors.name = 'Required'
    } 
    if (!values.date) {
        errors.date = 'Required'
    }
	return errors;
}

function mapStateToProps({ ClinicalTrialDocuments }) {
	if( ClinicalTrialDocuments && ClinicalTrialDocuments.id){
        return { initialValues: ClinicalTrialDocuments, ClinicalTrialDocuments : ClinicalTrialDocuments };
    }else{
        return {ClinicalTrialDocuments};
    }
	
	
}

ClinicalTrialDocumentForm = reduxForm({ validate, form: "ClinicalTrialDocumentFormmm" })(ClinicalTrialDocumentForm);

export default connect(mapStateToProps)(ClinicalTrialDocumentForm);