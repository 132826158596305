import React, { Component } from 'react';
import { connect } from "react-redux";
import { ClinicalTrialDocument_Create } from "../../actions";
import Header from "../layout/Header"
import Footer from "../layout/Footer"; 
import ClinicalTrialDocumentForm from "./ClinicalTrialDocumentForm"
import TopMenu from "../layout/TopMenu"
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import { SaveLog } from '../../utils';
class ClinicalTrialDocumentCreate extends Component {
    
    constructor(props) {
        super(props);
        this.state = { 
            log_reference :new Date().getTime()
        }; 
    }
    componentWillMount() {
        SaveLog(this.state.log_reference, "Create : Clinical trial documents", true)       
    }
    componentWillUnmount(){   
        SaveLog(this.state.log_reference, "",  false)        
    }

    handleSubmit(val) {
        // Do anything you want with the form value
        console.log(val);
      }        
    render () {
        const { formValues, ClinicalTrialDocuments, ClinicalTrialDocument_Create } = this.props
        console.log(this.props);
        

        return (
            <div>
                
                <Header />
                <div className="container-fluid">
                    <div className="row">
                        <div className="box-tab-fixed w-100">
                            <TopMenu />
                        </div>
                    </div>

                    <div className="row row-table-content">
                        <div className="box-table w-100" style={{ 'paddingTop' : '15px' }}>
                            <div>
                            <Link to="/clinical_trial_document/list"><Button variant="secondary" style={{ 'marginBottom' : '8px' }}> Clinical trial document </Button></Link>
                              
                            <ClinicalTrialDocumentForm onSubmitForm={() => ClinicalTrialDocument_Create(formValues)} title="Create Clinical Trial Document" /> 
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

function mapStateToProps({ form, ClinicalTrialDocuments }) {
	return { formValues: form.ClinicalTrialDocumentFormmm ? form.ClinicalTrialDocumentFormmm.values : null, ClinicalTrialDocuments };
}

export default connect(mapStateToProps, { ClinicalTrialDocument_Create })(ClinicalTrialDocumentCreate);