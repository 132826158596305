import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLogin, isAdmin, isDev, isUser,isCustomer } from '../utils';
import Forbidden from "./Error/Forbidden"
const PrivateRoute = ({component: Component, ...rest}) => {
    console.log(rest.role)
    //console.log(Component) 
    let check_access = false
    if(rest.role !== undefined){
        if(rest.role.includes('Dev') && isDev()){
            check_access = true
        }
        if(rest.role.includes('Admin') && isAdmin()){
            check_access = true
        }
        if(rest.role.includes('Customer') && isCustomer()){
            check_access = true
        }
        if(rest.role.includes('User') && isUser()){
            check_access = true
        }
    }
    if(rest.role === undefined){
        check_access = true
    }
    if(!isLogin()){
        check_access = false
    }

    return (
    <Route {...rest} render={props => (
        check_access ?
            <Component {...props} />
        : <Redirect to="/login" />
    )} />
    );
    /*
    if(rest.role !== undefined && rest.role === "Admin"){
        return (
            
            // Show the component only when the user is logged in
            // Otherwise, redirect the user to /login page
            //<Route exact path="/login" component={Login} /> 
            <Route {...rest} render={props => (
                isAdmin() ?                
                    <Component {...props} />
                : <Forbidden />
            )} />
        );
    }else{
        return (
            
            // Show the component only when the user is logged in
            // Otherwise, redirect the user to /login page
            <Route {...rest} render={props => (
                isLogin() ?
                    <Component {...props} />
                : <Redirect to="/login" />
            )} />
        );
    }
    */
};

export default PrivateRoute;