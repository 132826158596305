import jwt_decode from "jwt-decode";
import {withAuth} from '../actions/header_authorization';
import axios from "axios";
function GetToken(){
    const token = localStorage.getItem('token');
    //console.log('isLogin ----');
    //console.log(token)
    if (token === "undefined"){
        localStorage.removeItem('token')
        return false
    }
    if(token === null){
        return false
    }
    if(jwt_decode(token).isAdmin === undefined){  //fornew key   
        localStorage.removeItem('token')   
        return false
    }
    return token
}
export const isLogin = () => {
    const token = GetToken();      
    if (token) {
        const tokenExpiration = jwt_decode(token).exp;
        if (tokenExpiration > new Date().getTime() / 1000) {
            //console.log(' -live login ');
            return true;
        }
    }
    return false;
}

export const isAdmin = () => {
    const token = GetToken();  
    //console.log(jwt_decode(token))   
    if (token) { 
        if (jwt_decode(token).isAdmin) {             
            return true;
        }
    }
    return false;
}
export const isUser = () => {
    const token = GetToken();  
    //console.log(jwt_decode(token))   
    if (token) { 
        if (jwt_decode(token).role == "USER") {             
            return true;
        }
    }
    return false;
}
export const isDev = () => {
    const token = GetToken();  
    //console.log(jwt_decode(token))   
    if (token) { 
        if (jwt_decode(token).role == "DEV") {             
            return true;
        }
    }
    return false;
}
export const isCustomer = () => {
    const token = GetToken();  
    //console.log(jwt_decode(token))   
    if (token) { 
        if (jwt_decode(token).role == "CUSTOMER") {             
            return true;
        }
    }
    return false;
}

export function SaveLog(reference, detail, open){   
     
    const postData = {
        'reference' : reference,
        'url' : window.location.href,
        'detail' : detail,
        'open' : open       
    }
    axios.post(`${process.env.REACT_APP_API_LARAVEL}/api/log`, postData ,{
        headers: withAuth({ 'Content-Type': 'application/json' })
    })
    .then(res => {
        //console.log(res);
    })
    .catch(err => {
        console.log(err)
    })      
}

export const treatmentLine = [
    {label : 'Neoadjuvant', value : 'neo-adjuvant'},
    {label : 'Adjuvant', value : 'adjuvant'},
    {label : '1L', value : 'First line'},
    {label : '2L', value : 'Second line'},
    {label : '3L', value : 'Third line'},
    {label : 'Rescue', value : 'Rescue line'},
    {label : 'Maintenance', value : 'Maintenance'},
];

export const CountryArr = [
    {'name' : 'ALL', 'flag' : 'world', 'value' : 'ALL'},
    {'name' : 'AUSTRALIA', 'flag' : 'au', 'value' : 'Pbac'},
    {'name' : 'CANADA', 'flag' : 'ca', 'value' : 'Perc'},
    {'name' : 'EMA', 'flag' : 'ema', 'value' : 'Ema'},
    {'name' : 'ENGLAND', 'flag' : 'england', 'value' : 'Nice'},
    {'name' : 'FDA', 'flag' : 'us', 'value' : 'Fda'},
    {'name' : 'FRANCE', 'flag' : 'fr', 'value' : 'Transparence'},
    {'name' : 'GERMANY', 'flag' : 'g', 'value' : 'Gba'},
    {'name' : 'ITALY', 'flag' : 'it', 'value' : 'Italy'},
    {'name' : 'SPAIN', 'flag' : 'es', 'value' : 'Spain'},            
    {'name' : 'JAPAN', 'flag' : 'jp', 'value' : 'Pmda'},                       
    {'name' : 'NETHERLANDS', 'flag' : 'nd', 'value' : 'Zinl'},                
    {'name' : 'COLOMBIA', 'flag' : 'co', 'value' : 'Colombia'},    
];

export const DrugName = ['ALL', 'Bavencio', 'Imfinzi','Keytruda', 'Libtayo', 'Opdivo', 'Tecentriq', 'Zynyz', 'Jemperli', 'Tyvyt', 'Tuoyi']; 

export const rename_country = (country) => {
    switch (country) {
        case "FDA":
            country = "US (FDA)"                
            break;
        case "EMA":
            country = "EU"                
            break;
        case "ENGLAND":
            country = "UK"                
            break;
    }
    return country;
}

export const gen_css = (arr) => {
    let color = "";
    
    //arr = arr.filter((item, index) => arr.indexOf(item) === index);
    const arr_color = {
        'green': '#9bb713',
        'orange': '#FF9800',
        'purple': 'purple',
        'red': 'red',
        'gray': '#c5c3c3',
        'yellow': 'yellow',
        'pink': 'pink',
        'white': '#fff',
        'black': '#000',
        'green2' : '#6b824d',            
        'green3' : '#c8d68f'
    };

    switch (arr.length) {
        case 2:
            color = `${arr_color[arr[0]]} 0, ${arr_color[arr[0]]} 50%, ${arr_color[arr[1]]} 50%, ${arr_color[arr[1]]} 100% `;
            break;
        case 3:
            color = `${arr_color[arr[0]]} 0, ${arr_color[arr[0]]} 33%, ${arr_color[arr[1]]} 33%, ${arr_color[arr[1]]} 66%, ${arr_color[arr[2]]} 66%, ${arr_color[arr[2]]} 100%  `;
            break;
        case 4:
            color = `${arr_color[arr[0]]} 0, ${arr_color[arr[0]]} 25%, ${arr_color[arr[1]]} 25%, ${arr_color[arr[1]]} 50%, ${arr_color[arr[2]]} 50%, ${arr_color[arr[2]]} 76%, ${arr_color[arr[3]]} 76%, ${arr_color[arr[3]]} 100% `;
            break;
        case 5:
            color = `${arr_color[arr[0]]} 0, ${arr_color[arr[0]]} 20%, ${arr_color[arr[1]]} 20%, ${arr_color[arr[1]]} 40%, ${arr_color[arr[2]]} 40%, ${arr_color[arr[2]]} 60%, ${arr_color[arr[3]]} 60%, ${arr_color[arr[3]]} 80%, ${arr_color[arr[3]]} 80%, ${arr_color[arr[3]]} 100%  `;
            break;
        case 6:
            color = `${arr_color[arr[0]]} 0, ${arr_color[arr[0]]} 17%, ${arr_color[arr[1]]} 17%, ${arr_color[arr[1]]} 34%, ${arr_color[arr[2]]} 34%, ${arr_color[arr[2]]} 50%, ${arr_color[arr[3]]} 50%, ${arr_color[arr[3]]} 66%, ${arr_color[arr[3]]} 66%, ${arr_color[arr[3]]} 83%, ${arr_color[arr[3]]} 83%, ${arr_color[arr[3]]} 100% `;
            break;
        default:
            color = `${arr_color[arr[0]]} 0, ${arr_color[arr[0]]} 100% `;
            break;
    }

    return `linear-gradient(to right, ${color})`;
}

export const smr_type = (arr) => {
    const search_array = {
        "insuffisant" : "Insufficient SMR (SMRi)",
        "faible" : "Low",
        "modéré" : "Moderate",
        "non évaluable" : "n/a",
        "important" : "Substantial",
        }; 
    if (arr in search_array) {
        return search_array[arr];
    }else{
        return arr
    }
}

export const gba_added_benefit_id = (arr) => {
    const search_array = {
        '2' : 'Major/Significant', 
        '3' : 'Considerable',
        '4' : 'Minor/Marginal',
        '5' : 'Non-quantifiable',
        '6' : 'Not proven',
        '7' : 'Less benefit'
        }; 
    if (arr in search_array) {
        return search_array[arr];
    }else{
        return null
    }
}


export const recommendation = (agency, param) => {
    let list = {};
    switch (agency) {
        case 'Italy':
            list = {
                'Yes' : 'Reimbursed',
                'No' : 'Not Reimbursed',
                'Partial' : 'Reimbursement with limitation beyond label',                
            }
            break;
        case 'Spain':
        case 'Zinl':
            list = {
                'Yes' : 'Included',
                'No' : 'Not include',
                'Partial' : 'Included with specific conditions',                
            }
            break;
        default:
            break;
    }
   
    if (param in list) {
        return list[param];
    }else{
        return param
    }
}

export const uniqueArray = (arr) => {
    let uniqueChars = [];
    arr.forEach((element) => {
        if (!uniqueChars.includes(element)) {
            uniqueChars.push(element);
        }
    });
    return uniqueChars;
}

export const path_detail = (id, agency) => {
    if(agency === "Transparence"){
        return `https://www.prismaccess.com/detail/${id}/trs`;
    }else{
        return `https://www.prismaccess.com/detail/${id}/${agency.toLowerCase()}`;
    }
}
export const userRole = [
    {
      label: "ADMIN",
      value: 2
    },
    {
      label: "POWER USER",
      value: 3
    },
    {
      label: "USER",
      value: 4
    },
];

