import {
	REIMBURSEMENTS_FETCH, REIMBURSEMENT_FETCH, REIMBURSEMENT_CREATE, REIMBURSEMENT_UPDATE, REIMBURSEMENT_FETCH_NEW,REIMBURSEMENT_FETCH_NEW_LOADDING
} from "../actions/types";

export default function(state = {data:[], loadding:false}, action) {
	switch (action.type) {
		case REIMBURSEMENTS_FETCH:
        case REIMBURSEMENT_FETCH:
        case REIMBURSEMENT_FETCH_NEW:
            return { ...state, loadding: false, data:action.payload}            
        case REIMBURSEMENT_FETCH_NEW_LOADDING:
            return { ...state, loadding: true}           
        case REIMBURSEMENT_CREATE:
            return { saved: true, msg: "Data Saved Successfully" };            
        case REIMBURSEMENT_UPDATE:
            return { ...state, saved: true, msg: "Data Saved Successfully" };  
		default:
			return state;
    } 
}