import React, { Component } from 'react';
import { connect } from "react-redux";
import { createUser, updateUser, getById } from "../../actions";
import { withRouter } from 'react-router-dom';
import { userRole } from "../../utils"

class UserForm extends Component {
    
    constructor(props) {
        super(props);
        
        // this.onChangeTitle = this.onChangeTitle.bind(this);
        // this.onChangeDescription = this.onChangeDescription.bind(this);
        // this.saveTutorial = this.saveTutorial.bind(this);
        // this.newTutorial = this.newTutorial.bind(this);
        this.onHandleSubmit = this.onHandleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.ObjForm = {            
            name: "",
            email: "",
            password: "",
            confirmpassword: "",
            role: ""
        }
        this.state = {
            form: this.ObjForm,
            error : this.ObjForm,
            message : "",
            type_alert : "",
            load : false
        };
    }  
    newForm() {
        this.setState({
            form : this.ObjForm,
            error : this.ObjForm
        });
    }  
    componentDidMount(){
       
        if (this.props.match && this.props.match.params.id) {
            this.setState({load : true});
            this.props.getById(this.props.match.params.id)
            .then((reponse) => {
                console.log(reponse)
                if(reponse.role_id === 2){
                    alert('Unauthorized');
                    this.props.history.push('/users');
                }
                this.setState({
                    form : {
                        name: reponse.name,
                        email: reponse.email,
                        role: reponse.role_id
                    },
                    load : false
                });               
            })
            .catch((e) => {                
                this.setState({message : "Load data error !!!", type_alert : "danger", load : false});
                console.log(e);
            });
        }
    }    
    handleChange (event,state_name) {
        console.log(event.target.value)   
        const val = event.target.value;
        this.setState( prevState  => ({
            form : {
                ...prevState.form,
                [state_name]: val
            }
        }));
    }
    
    validate(values) {
        const errors = {};
        if (!values.name) {
            errors.name = 'Required'
        } 
        if (!values.email) {
            errors.email = 'Required'
        }else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = "Invalid email address";
        }
        if(!this.props.match.params.id){
            if (!values.password) {
                errors.password = 'Required'
            }        
            if (!values.confirmpassword ) {
                errors.confirmpassword = 'Required' ;
            } else if (values.confirmpassword !== values.password) {
                errors.confirmpassword = 'Password mismatched' ;
            }
        }        
        if (!values.role) {
            errors.role = 'Required'
        }
        
        if(Object.entries(errors).length){
            this.setState( prevState  => ({
                error : {
                    ...prevState.error,
                    ...errors
                }
            }));
            return false;
        }else{
            this.setState({error : this.ObjForm });
            return true;
        }
        
    }

    onHandleSubmit(event){
        event.preventDefault();
        console.log('onHandleSubmit');

        if(!this.validate(this.state.form)){            
            return false;
        }
        
        console.log(this.state.form);
        console.log(this.state.error);
        this.setState({message : "", type_alert : "", load : true});
        if (this.props.match && this.props.match.params.id) { //update
            this.props.updateUser(this.props.match.params.id, this.state.form)
            .then((reponse) => {
                console.log(reponse)
                this.setState({message : "Update Successfully", load : false});
                                
            })
            .catch((e) => {                
                this.setState({message : "Update Error !!!", type_alert : "danger", load : false});
                console.log(e);
            });
        }else{
            this.props.createUser(this.state.form)
            .then((reponse) => {
                console.log(reponse)
                this.setState({message : "Register Successfully", load : false});
                this.newForm();
            })
            .catch((e) => {                
                this.setState({message : "Register Error !!!", type_alert : "danger", load : false});
                console.log(e);
            });
        }
        
 
    }
    render () {
        //const { store_user } = this.props;
        
          
        return (
            <div>
           
          
                
                <div className="row row-table-content">
                    <div className="box-table w-100" style={{paddingTop:10}}>    
                        <form className="form-signin main-overlay" onSubmit={this.onHandleSubmit}>                     
                            <h1 className="h3 mb-3 font-weight-bold text-center">{this.props.title}</h1>
                            { this.state.message && <div className={`alert alert-${this.state.type_alert ? this.state.type_alert : "success"} title`} role="alert">
								{this.state.message}
							</div>}
                            <div className="form-group">
                                <label><strong>Name</strong></label> 
                                <input name="name" type="text" required={true} value={this.state.form.name} placeholder="Enter your first name" className="form-control" onChange={(e) => this.handleChange(e,"name")} />  
                            </div>
                            <div className="form-group">
                                <label><strong>Email address</strong></label> 
                                <input name="email" type="email" required={true} value={this.state.form.email} disabled={!this.props.match.params.id ? false : true} placeholder="Enter email" className="form-control" onChange={(e) => this.handleChange(e,"email")} />  
                                {this.state.error.email && 
                                        (<div className="mt-2 text-danger title">{this.state.error.email}</div>)}   
                            </div>
                            {this.props.match && !this.props.match.params.id && (
                            <>
                            <div className="form-group">
                                <label><strong>Password</strong></label> 
                                <input name="password" type="password" required={true} value={this.state.form.password} placeholder="Password" className="form-control" onChange={(e) => this.handleChange(e,"password")} />  
                                {this.state.error.password && 
                                        (<div className="mt-2 text-danger title">{this.state.error.password}</div>)}   
                            </div>
                            <div className="form-group">
                                <label><strong>Confirm Password</strong></label> 
                                <input name="confirmpassword" type="password" required={true} value={this.state.form.confirmpassword} placeholder="Confirm Password" className="form-control" onChange={(e) => this.handleChange(e,"confirmpassword")} />  
                                {this.state.error.confirmpassword && 
                                        (<div className="mt-2 text-danger title">{this.state.error.confirmpassword}</div>)}   
                            </div>  
                            </>
                            )}       
                            <div className="form-group">
                                <label><strong>Role</strong></label> 
                                <select name="role" required={true} className="form-control" value={this.state.form.role}  onChange={(e) => this.handleChange(e,"role")} > 
                                <option></option>
                                {this.props.match && this.props.match.params.id && userRole.slice(1).map(function(item, i){                                    
                                    return <option key={i}  value={item['value']}>{item['label']}</option>
                                })}
                                {this.props.match && !this.props.match.params.id && userRole.map(function(item, i){                                    
                                    return <option key={i}  value={item['value']}>{item['label']}</option>
                                })}
                                </select> 
                                {this.state.error.role && 
                                        (<div className="mt-2 text-danger title">{this.state.error.role}</div>)}   
                            </div>
                            
                            <button type="submit" className="btn btn-lg btn-primary btn-block" > Submit </button>
                            {this.state.load && (
                                <div className="overlay" ></div>
                                )
                            }
                        </form>
                        
                    </div>                    
                </div>
         
           
            </div>
        )
    }
}

 
function mapStateToProps({ store_user }) {
	return { store_user };
}


export default withRouter(connect(mapStateToProps, { createUser, updateUser, getById })(UserForm));
