import {
	HtaDocument_FETCH, HtaDocuments_FETCH, HtaDocument_CREATE, HtaDocument_UPDATE
} from "../actions/types";

var initialState = []
export default function(state = initialState, action) {
	switch (action.type) {
        case HtaDocument_FETCH:
        case HtaDocuments_FETCH:    
            return action.payload;
		case HtaDocument_CREATE: 
            return { saved: true, msg:action.msg, type_alert:action.type_alert }
        case HtaDocument_UPDATE: 
            return { ...state, saved: true, msg:action.msg, type_alert:action.type_alert }         
		default:
			return state;
    } 
}