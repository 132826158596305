import React, { Component } from "react";
import Header from "../../components/layout/Header";
import Footer from "../../components/layout/Footer";
import { connect } from "react-redux";
import { reimbursementsFetch, reimbursementDelete } from "../../actions";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ReimbursementList extends Component {
    
    componentDidMount() {
        this.props.reimbursementsFetch();
    }


    render() {
        return (
            <div>
                <Header />  
                <div className="container">
                    <div style={{ minHeight: '92vh', paddingTop: '73px' }}>
                        <div className="text-right">
                        <Link className="btn btn-primary" to="/reimbursement/create">Add</Link>
                        </div>
                        <table className="table table-bordered text-center" style={{ marginTop: '10px' }}>
                            <thead>
                                <tr>
                                    <th width="40%">Name</th>
                                    <th width="15%">File Excel</th>
                                    <th width="15%">created</th>
                                    <th width="15%">modified</th>
                                    <th width="15%">Action</th> 
                                </tr>
                            </thead>
                            <tbody>
                            { (this.props.reimbursements.records ?
                                (this.props.reimbursements.records.map((reimbursement,i) => (
                                <tr key={i}>
                                    <td>{reimbursement.name}</td>
                                    <td>{reimbursement.excel}</td>
                                    <td>{reimbursement.created}</td>
                                    <td>{reimbursement.modified}</td>
                                    <td>
                                    <Link to={"/reimbursement/" + reimbursement.id} className="btn btn-info  btn-circle"><FontAwesomeIcon icon="search"  /></Link>
                                    </td>
                                </tr>)))

                                : (<tr>
                                    <th colSpan="4" className="text-center"> Empty Data !!! </th>
                                </tr>)
                            )}
                            </tbody>
                        </table>


                    </div>
                </div>
                <Footer />   
            </div>
        )
    }
}
function mapStateToProps({ reimbursements }) {
    return { reimbursements };
}


export default connect(mapStateToProps, { reimbursementsFetch, reimbursementDelete })(ReimbursementList);