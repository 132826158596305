import React, { Component } from 'react'
import {ReimbursementTableColumn} from './ReimbursementTableColumn'
import { treatmentLine } from '../../utils'

 

export default class ReimbursementTable extends Component {

    
    render() {

        const dataTables = this.props.dataTable.datas;
        const loop_row = [];
        let temp_category = "";
        
        
        if(dataTables !== undefined && dataTables !== null){
            dataTables.forEach( (value, key) => {
                if(!value.datas || value.datas.length === 0){
                    return;
                }
                
                //console.log(value);
                let styleborder = false;
                if(temp_category !== value.category && temp_category !== ""){
                    styleborder = true;
                }
                temp_category = value.category;
                loop_row.push(
                    <tr key={key} style={{ borderTop : styleborder ? "solid 2px" : "" }} >
                        <td><span>{value.display}</span></td>
                        {treatmentLine.map((treatment_line,index)=>{
                            return <>
                                <ReimbursementTableColumn type="Mono" datas={value.datas} treatment_line={treatment_line} indexation={value.display} onClickIndication={this.props.onClickIndication} />
                                <ReimbursementTableColumn type="Combo" datas={value.datas} treatment_line={treatment_line} indexation={value.display} onClickIndication={this.props.onClickIndication} />
                            </>
                        })}
                        
                    </tr>
                );
            });
             
          
        }

        return (
            
            <div className="child-box-right">
                <div className="table-responsive main-overlay">
                    <table className="table-information table-striped">
                        <thead>
                            <tr className="header-tab_1">
                                <th>{this.props.name}</th>
                                {treatmentLine.map((item,index)=>{
                                    return <th key={index} colSpan={2}>{item.label}</th>
                                })}
                            </tr>
                            <tr className="header-tab_2">
                                <th style={{ width: '16%' }}>SETTING</th>

                                {treatmentLine.map((item,index)=>{
                                    return <>
                                            <th key={index+"c"} style={{ width: '5%' }}>Mono</th>
                                            <th key={index+"d"} style={{ width: '5%' }}>Combo</th>
                                        </>
                                })}
                                
                            </tr>
                        </thead>
                        <tbody>
                            { loop_row }
                            { (loop_row.length === 0) && 
                                (                                
                                    <tr>
                                        <td colSpan="15" className="text-center">{this.props.loadding ? 'Loading' : 'No Data'}</td>                                        
                                    </tr>
                                )
                            }
                         


                         </tbody>
                    </table> 
                                       
                    {this.props.loadding && (
                        <div className="overlay" >                           
                            {/* <i class="fa fa-refresh fa-spin"></i> */}
                        </div>
                        )
                    }
                    
                </div>
            </div>

        )
    }
}
